import React from 'react';
import PropTypes from 'prop-types';
import ShareIcon from '@material-ui/icons/Share';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {withStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const styles = theme => ({
    shareIcon: {
        fontSize: "1.8rem",
    },
    shareButton: {
        padding: '1rem'
    },
    menuIcon: {
        fontSize: '1.8rem',
        margin: 0
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    primary: {},
});

class SocialMediaMenu extends React.Component {
    state = {
        open: false,
    };

    handleToggle = () => {
        this.setState(state => ({open: !state.open}));
    };

    handleClickItem = (event) => {
        let clickedIndex = event.target.value;
        this.props.menuItems[clickedIndex].onClick(this.props.cardIndex);
        this.setState({anchorEl: null, open: false});
    };


    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;
        const {open} = this.state;

        return (
            <span>
                <IconButton
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                >
                   <MoreVertIcon/>
                </IconButton>

                <Popper open={open} anchorEl={this.anchorEl} transition style={{ zIndex: 100 }}>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{transformOrigin: placement === 'bottom' ? 'right top' : 'center bottom'}}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        {this.props.menuItems.map((item, index) => (
                                            <MenuItem key={item.text} value={index} onClick={this.handleClickItem}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </span>
        );
    }
}


SocialMediaMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialMediaMenu);