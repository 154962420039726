import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
        width: "100%",
    },
    group: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
});

class RadioButtonsGroup extends React.Component {
    state = {
        value: 'Other',
    };

    handleChange = event => {
        this.props.gender.updateGender(event.target.value);
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <RadioGroup aria-label="Gender" name="gender1" className={classes.group} value={this.props.gender.option} onChange={this.handleChange}>
                        <FormControlLabel value="Female" control={<Radio style={{color: this.props.color}}/>} checked={this.props.gender.option === 'Female'} label="Stúlka"/>
                        <FormControlLabel value="Male" control={<Radio style={{color: this.props.color}}/>} checked={this.props.gender.option === 'Male'} label="Drengur"/>
                        <FormControlLabel value="Other" control={<Radio style={{color: this.props.color}}/>} checked={this.props.gender.option === 'Other'} label="Ekki vitað"/>
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}

RadioButtonsGroup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtonsGroup);