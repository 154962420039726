import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CustomNameInput from "./inputTypes/CustomNameInput";
import RandomNameInput from "./inputTypes/RandomNameInput";
import CardHeader from "@material-ui/core/CardHeader";
import CardMenu from "./CardMenu"

const id = 0;

class InputWrapper extends Component {

    style = {
        card: {

        },
        contentStyle: {
            'minHeight': "100px",
            'marginTop': '-20px',
            'padding': '10px',
        },
        headerStyle: {
            'padding': '10px 23px 0px 15px',
            'minHeight': '50px'
        }
    };


    render() {
        let button;

        if (this.props.card.type === 'RandomNameInput') {
            button = <RandomNameInput color={this.props.color} cardIndex={this.props.cardIndex} card={this.props.card} />
        } else {
            button = <CustomNameInput cardIndex={this.props.cardIndex} card={this.props.card}/>
        }

        return (
            <Grid item key={id} xs={10} sm={7} md={3} lg={3}>
                <Card style={this.style.card}>
                    <CardHeader style={this.style.headerStyle} title={this.props.card.title}
                                action={this.props.card.title !== "Eftirnafn" ? <CardMenu cardIndex={this.props.cardIndex} menuItems={this.props.card.menuItems}/> : null}
                    />
                    <CardContent style={this.style.contentStyle}>
                        {button}
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}


export default InputWrapper

