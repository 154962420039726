import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Textfit} from "react-textfit";
import NameTooltip from "./NameTooltip";


const styles = theme => ({
    heroUnit: {
        minHeight: '10em',
        backgroundColor: theme.palette.background.paper,
    },
    heroContent: {
        padding: "55px 15px 40px 15px"
    }
});

class NameDisplay extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.heroUnit}>
                <div align="center" className={classes.heroContent}>
                    <Textfit mode="single">
                        {this.props.cards.map((card, index) => (
                            <NameTooltip key={index} toggleInputType={this.props.toggleInputType} card={card} index={index}/>
                        ))}
                    </Textfit>
                </div>
            </div>
        );
    }
}

NameDisplay.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NameDisplay);