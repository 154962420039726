import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Textfit} from "react-textfit";
import Tooltip from "@material-ui/core/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUnlock, faLock} from '@fortawesome/free-solid-svg-icons'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";


const styles = () => ({
    lockIcon: {
        width: "100%",
        fontSize: "2.5rem",
        padding: "8px"
    }
});

class NameDisplay extends React.Component {
    state = {
        open: false,
    };

    handleTooltipClose = () => {
        this.setState({open: false});
    };

    handleTooltipOpen = () => {
        this.setState({open: true});
    };

    handleOnClick = (index) => {
        this.props.toggleInputType(index);
        // this.setState({open: false});
    };

    render() {
        const {classes} = this.props;

        const getIcon = (index, type) => {
            if (type === "RandomNameInput") {
                return <FontAwesomeIcon className={classes.lockIcon} icon={faLock} onClick={() => this.handleOnClick(index)}/>;
            } else {
                return <FontAwesomeIcon className={classes.lockIcon} icon={faUnlock} onClick={() => this.handleOnClick(index)}/>;
            }
        };

        return (
            <React.Fragment>
                <style jsx="true">{`
                     div[role=tooltip] div {
                            margin: -4px 0 0 0;
                            padding: 0;
                      }
                 `}
                </style>
                <ClickAwayListener onClickAway={this.handleTooltipClose}>
                    <Tooltip
                        className={"nameTooltip"}
                        enterDelay={500}
                        leaveDelay={33300}
                        onClose={this.handleTooltipClose}
                        title={getIcon(this.props.index, this.props.card.type)}
                        open={this.state.open}
                        interactive
                    >
                        <span onClick={this.handleTooltipOpen}> {this.props.card.input} </span>
                    </Tooltip>
                </ClickAwayListener>
            </React.Fragment>
        );
    }
}

NameDisplay.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NameDisplay);