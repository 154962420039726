import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Card from "@material-ui/core/Card/Card";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeart} from '@fortawesome/free-solid-svg-icons'

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        minHeight: 300,
    },
    heartButton: {
        padding: '0'
    },
    heroContent: {
        paddingTop: '8vh',
        minHeight: '28vh',
        padding: `${theme.spacing.unit * 2}px`,
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
    },
});

class NameHistory extends React.Component {
    state = {
        checked: 0,
    };

    handleToggle = key => () => {
        this.props.toggleNameHistoryHeart(key);
    };

    render() {
        const {classes} = this.props;

        return (
            <div align="center" className={classes.heroContent}>
                <Card className={classes.root}>
                    <List>
                        {this.props.nameHistory.map((value, key) => (
                            <ListItem key={value.name} role={undefined} onClick={() => this.props.rollBack(value)} button dense>
                                {value.name}
                                <ListItemSecondaryAction>
                                    <IconButton className={classes.heartButton} aria-label="Comments">
                                        <Checkbox icon={<FontAwesomeIcon icon={faHeart}/>} checkedIcon={<FontAwesomeIcon icon={faHeart}/>} checked={value.heartCheck} tabIndex={-1} onClick={this.handleToggle(key)}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Card>
            </div>
        );
    }
}

NameHistory.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NameHistory);