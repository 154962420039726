import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import {DebounceInput} from 'react-debounce-input';

class CustomNameInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.input
        };
    }

    handleChange = () => event => {
        this.props.card.updateInput(this.props.cardIndex, event.target.value);
    };

    render() {
        return (
            <DebounceInput fullWidth element={TextField} debounceTimeout={300} margin="normal" variant="outlined" value={this.props.card.input} onChange={this.handleChange('name')}/>

        );
    }
}

export default CustomNameInput

