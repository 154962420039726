export default [
    'Himinljómi',
    'Mjöllnir',
    'Issi',
    'Þóri',
    'Sverre',
    'Ísmar',
    'Evald',
    'Karim',
    'Haddi',
    'Hrafnþór',
    'Brímir',
    'Elvin',
    'Kinan',
    'Aaron',
    'Diljar',
    'Marínó',
    'Ígor',
    'Lyngþór',
    'Júní',
    'Líam',
    'Dynþór',
    'Tími',
    'Kjárr',
    'Runi',
    'Ónar',
    'Bylur',
    'Öxar',
    'Þrymir',
    'Nóam',
    'Birtingur',
    'Þórar',
    'Tóti',
    'Mosi',
    'Mummi',
    'Lótus',
    'Snjóki',
    'Röskvi',
    'Fíus',
    'Leif',
    'Sporði',
    'Rex',
    'Morgan',
    'Eros',
    'Huxley',
    'Cæsar',
    'Rikki',
    'Toddi',
    'Alli',
    'Bastían',
    'Ugluspegill',
    'Kamilus',
    'Petter',
    'Lér',
    'Steðji',
    'Lundi',
    'Hvannar',
    'Julian',
    'Þróttur',
    'Elía',
    'Emerald',
    'Dittó',
    'Werner',
    'Þórbjarni',
    'Kastíel',
    'Evan',
    'Mateó',
    'Gestar',
    'Æsir',
    'Þinur',
    'Kjói',
    'Korri',
    'Þórbjörn',
    'Bassi',
    'Reinhold',
    'Tjaldur',
    'Skellir',
    'Gael',
    'Lárent',
    'Þjóðar',
    'Reynarð',
    'Járngrímur',
    'Vili',
    'Maximus',
    'Sigurlogi',
    'Nikanor',
    'Skröggur',
    'Benidikt',
    'Gígur',
    'Antóníus',
    'Tói',
    'Kórekur',
    'Melkíor',
    'Laugi',
    'Freymann',
    'Ronald',
    'Lyngar',
    'Kristofer',
    'Veróna',
    'Berni',
    'Kjarval',
    'Jötunn',
    'Kaktus',
    'Tobbi',
    'Móri',
    'Vöttur',
    'Lóni',
    'Berti',
    'Skær',
    'Kris',
    'Þyrill',
    'Ástvald',
    'Brynmar',
    'Geirhjörtur',
    'Annmar',
    'Levý',
    'Kaspar',
    'Greppur',
    'Borgúlfur',
    'Jónsi',
    'Ismael',
    'Viðjar',
    'Geimar',
    'Lúis',
    'Ernest',
    'Nóri',
    'Eir',
    'Kai',
    'Fossmar',
    'Friðríkur',
    'Dengsi',
    'Ásar',
    'Bjarkan',
    'Kubbur',
    'Mark',
    'Sæbjartur',
    'Nenni',
    'Ísbjörn',
    'Alan',
    'Edilon',
    'Eyjar',
    'Hjaltalín',
    'Fjarki',
    'Nathan',
    'Eldon',
    'Úlftýr',
    'Baddi',
    'Kali',
    'Kristó',
    'Dolli',
    'Cesar',
    'Ónarr',
    'Svani',
    'Vápni',
    'Ebbi',
    'Pedró',
    'Brimþór',
    'Maríon',
    'Sæi',
    'Gaddi',
    'Gill',
    'Íseldur',
    'Emir',
    'Dexter',
    'Jonni',
    'Merkúr',
    'Náttúlfur',
    'Sæmi',
    'Rögnvald',
    'Manuel',
    'Kamal',
    'Keran',
    'Dante',
    'Thór',
    'Ári',
    'Hymir',
    'Sókrates',
    'Sveinar',
    'Ísrael',
    'Vindar',
    'Robert',
    'Dalí',
    'Svend',
    'Sturri',
    'Míó',
    'Lói',
    'Erasmus',
    'Mói',
    'Reykdal',
    'Fjalarr',
    'Rúbar',
    'Kraki',
    'Antonio',
    'Mikkael',
    'Patrek',
    'Náttmörður',
    'Karma',
    'Friedrich',
    'Vincent',
    'Róman',
    'Fannþór',
    'Dominik',
    'Tolli',
    'Evían',
    'Deníel',
    'Álfar',
    'Hylur',
    'Tíbor',
    'Aðalvíkingur',
    'Anor',
    'Dreki',
    'Baui',
    'Gulli',
    'Ram',
    'Sigurörn',
    'Góði',
    'Hugó',
    'Ísarr',
    'Jagger',
    'Eykam',
    'Bertram',
    'Þjóðann',
    'Silli',
    'Koggi',
    'Jörri',
    'Fertram',
    'Marzilíus',
    'Mildinberg',
    'Tarfur',
    'Fálki',
    'Andrean',
    'Snæbjartur',
    'Dorri',
    'Eberg',
    'Eikar',
    'Dalli',
    'Sólúlfur',
    'Móði',
    'Refur',
    'Sævarr',
    'Amor',
    'Dennis',
    'Ylfingur',
    'Elvis',
    'Marlon',
    'Guðsteinn',
    'Emmanúel',
    'Heiðarr',
    'Spói',
    'Asael',
    'André',
    'Þórinn',
    'Ásdór',
    'Jarfi',
    'Líus',
    'Olav',
    'Jamil',
    'Raknar',
    'Eiðar',
    'Neró',
    'Tonni',
    'Vinjar',
    'Kvasir',
    'Lindi',
    'Dúnn',
    'Sæmar',
    'Anfinn',
    'Bambus',
    'Stapi',
    'Kíran',
    'Amír',
    'Arngarður',
    'Fríðsteinn',
    'Mikkel',
    'Sveinbjartur',
    'Annarr',
    'Eivin',
    'Vígberg',
    'Moli',
    'Auður',
    'Birtir',
    'Hector',
    'Ermenrekur',
    'Gídeon',
    'Heisi',
    'Uxi',
    'Olli',
    'Bergjón',
    'Ellís',
    'Damon',
    'Bói',
    'Líó',
    'Aran',
    'Olavur',
    'Milli',
    'Geiri',
    'Galti',
    'Melrakki',
    'Gói',
    'Hrafntýr',
    'Rikharður',
    'Remek',
    'Hávarr',
    'Benvý',
    'Holger',
    'Brími',
    'Markó',
    'Styrr',
    'Eddi',
    'Willy',
    'Nývarð',
    'Diego',
    'Eldmar',
    'Ilías',
    'Amil',
    'Adrian',
    'Aðólf',
    'Auðberg',
    'Ólaf',
    'Sigri',
    'Hnikarr',
    'Hjalmar',
    'Jónbjarni',
    'Búri',
    'Vakur',
    'Addi',
    'Adíel',
    'Adríel',
    'Aðalbert',
    'Aðalráður',
    'Aðils',
    'Aldar',
    'Alrekur',
    'Annar',
    'Annes',
    'Ares',
    'Aríus',
    'Arnberg',
    'Arnes',
    'Arngils',
    'Arnmóður',
    'Aspar',
    'Auðgeir',
    'Auðkell',
    'Auðmundur',
    'Austar',
    'Austmann',
    'Austmar',
    'Austri',
    'Árgeir',
    'Árgils',
    'Ás',
    'Ásgautur',
    'Ásgils',
    'Ásröður',
    'Ástríkur',
    'Ástvar',
    'Ásvarður',
    'Baldwin',
    'Bambi',
    'Barri',
    'Beinir',
    'Bergfinnur',
    'Bergmar',
    'Bill',
    'Bjarnharður',
    'Bjarnlaugur',
    'Bjólan',
    'Bjólfur',
    'Björnólfur',
    'Blævar',
    'Bótólfur',
    'Bresi',
    'Brestir',
    'Brimi',
    'Brynsteinn',
    'Brynþór',
    'Bryntýr',
    'Bæron',
    'Dagfari',
    'Dalmann',
    'Dalvin',
    'Daríus',
    'Demus',
    'Díómedes',
    'Dómaldi',
    'Dómaldur',
    'Dónaldur',
    'Dór',
    'Dóri',
    'Dósóþeus',
    'Dufgus',
    'Dufþakur',
    'Dugfús',
    'Efraím',
    'Eggþór',
    'Elbert',
    'Eldgrímur',
    'Eldór',
    'Elfráður',
    'Elímar',
    'Elínbergur',
    'Elínmundur',
    'Engilbjartur',
    'Ernestó',
    'Eron',
    'Esekíel',
    'Estefan',
    'Eylaugur',
    'Eyríkur',
    'Fabrisíus',
    'Falgeir',
    'Fengur',
    'Fenrir',
    'Feykir',
    'Finngeir',
    'Finnvarður',
    'Fífill',
    'Fjólar',
    'Fólki',
    'Fránn',
    'Frár',
    'Freybjörn',
    'Freymundur',
    'Freyviður',
    'Friðmann',
    'Friður',
    'Fróðmar',
    'Fúsi',
    'Galdur',
    'Gaui',
    'Gautrekur',
    'Gautviður',
    'Geirhvatur',
    'Geirleifur',
    'Geirólfur',
    'Geirröður',
    'Geirþjófur',
    'Geirtryggur',
    'Geirvaldur',
    'Gellir',
    'Geri',
    'Gígjar',
    'Gjúki',
    'Glói',
    'Gneisti',
    'Goðmundur',
    'Grani',
    'Grankell',
    'Gregor',
    'Grímnir',
    'Guðleikur',
    'Guðmon',
    'Guðstein',
    'Gumi',
    'Gunnberg',
    'Gunnóli',
    'Gunnröður',
    'Gunnvaldur',
    'Gýgjar',
    'Hafgrímur',
    'Hafnar',
    'Hagbert',
    'Hallgarður',
    'Hallgils',
    'Hamar',
    'Hauksteinn',
    'Haukvaldur',
    'Háleygur',
    'Hárekur',
    'Hárlaugur',
    'Hásteinn',
    'Heiðmann',
    'Heilmóður',
    'Heinrekur',
    'Helmút',
    'Herfinnur',
    'Herlaugur',
    'Hervarður',
    'Hilaríus',
    'Hilbert',
    'Hildibergur',
    'Hildigeir',
    'Hildiglúmur',
    'Hildir',
    'Híram',
    'Hjallkár',
    'Hjálmgeir',
    'Hleiðar',
    'Hlégestur',
    'Hlíðberg',
    'Hljómur',
    'Hlöðmundur',
    'Hlöður',
    'Hlöðvarður',
    'Hnefill',
    'Hólmfastur',
    'Hóseas',
    'Hreiðmar',
    'Hrímnir',
    'Hrolleifur',
    'Hróaldur',
    'Hróðgeir',
    'Hróðólfur',
    'Hrómundur',
    'Hrútur',
    'Hrærekur',
    'Hugberg',
    'Hugleikur',
    'Húmi',
    'Húnröður',
    'Hyltir',
    'Hænir',
    'Immanúel',
    'Ingibert',
    'Ingivaldur',
    'Ingmar',
    'Ían',
    'Ími',
    'Ísmael',
    'Jaki',
    'Játgeir',
    'Jeremías',
    'Jói',
    'Jónar',
    'Jóndór',
    'Jóvin',
    'Júrek',
    'Jörfi',
    'Kakali',
    'Kaldi',
    'Kaprasíus',
    'Karli',
    'Kár',
    'Kilían',
    'Kjallakur',
    'Knörr',
    'Koðrán',
    'Kolskeggur',
    'Kolur',
    'Konstantínus',
    'Kristlaugur',
    'Kristvaldur',
    'Kristvarður',
    'Lambert',
    'Lár',
    'Lárentíus',
    'Leiðólfur',
    'Lindar',
    'Línberg',
    'Ljósálfur',
    'Ljúfur',
    'Loðmundur',
    'Lúter',
    'Lýtingur',
    'Magngeir',
    'Makan',
    'Mar',
    'Marbjörn',
    'Margrímur',
    'Marijón',
    'Marri',
    'Mars',
    'Marsellíus',
    'Mathías',
    'Melkólmur',
    'Mensalder',
    'Mikjáll',
    'Mír',
    'Mórits',
    'Muni',
    'Muninn',
    'Múli',
    'Mýrkjartan',
    'Nataníel',
    'Neisti',
    'Nonni',
    'Norðmann',
    'Normann',
    'Nóel',
    'Nóvember',
    'Oddkell',
    'Oddmar',
    'Oktavíus',
    'Októvíus',
    'Orfeus',
    'Orvar',
    'Otkell',
    'Otur',
    'Ómi',
    'Ósvífur',
    'Parmes',
    'Príor',
    'Ragúel',
    'Ráðgeir',
    'Ráðvarður',
    'Reginbaldur',
    'Reifnir',
    'Reinhart',
    'Reyr',
    'Ríó',
    'Rósmann',
    'Sandel',
    'Sandur',
    'Saxi',
    'Sesil',
    'Sigbergur',
    'Sigbjartur',
    'Sigfastur',
    'Sigfreður',
    'Siglaugur',
    'Sigtýr',
    'Sigurnýas',
    'Sigurnýjas',
    'Sírnir',
    'Sírus',
    'Skefill',
    'Skíði',
    'Skuggi',
    'Skúta',
    'Smiður',
    'Snæhólm',
    'Snælaugur',
    'Snæringur',
    'Soffanías',
    'Sophanías',
    'Sófónías',
    'Sólbergur',
    'Sólbjörn',
    'Sólimann',
    'Sólmar',
    'Spartakus',
    'Steinfinnur',
    'Steinkell',
    'Steinmann',
    'Steinröður',
    'Steinvarður',
    'Stórólfur',
    'Styrbjörn',
    'Svanbergur',
    'Svanhólm',
    'Svanmundur',
    'Sveinungi',
    'Svölnir',
    'Svörfuður',
    'Sæmann',
    'Sær',
    'Sölvar',
    'Tindar',
    'Tímoteus',
    'Tímóteus',
    'Tóki',
    'Tumas',
    'Ubbi',
    'Unnbjörn',
    'Urðar',
    'Úddi',
    'Úlfgeir',
    'Úlfkell',
    'Valbergur',
    'Valbrandur',
    'Valdi',
    'Valentín',
    'Valíant',
    'Vallaður',
    'Varmar',
    'Váli',
    'Ver',
    'Vestar',
    'Vestmar',
    'Vékell',
    'Vélaugur',
    'Vémundur',
    'Vigtýr',
    'Vigur',
    'Vilbjörn',
    'Vilgeir',
    'Vilji',
    'Villi',
    'Virgill',
    'Vígmar',
    'Vopni',
    'Vorm',
    'Ylur',
    'Yrkill',
    'Ýrar',
    'Zóphanías',
    'Þiðrandi',
    'Þjálfi',
    'Þjóðgeir',
    'Þjóðleifur',
    'Þjóðmar',
    'Þjóðrekur',
    'Þjóðvarður',
    'Þjóstar',
    'Þjóstólfur',
    'Þorbrandur',
    'Þorgarður',
    'Þorgautur',
    'Þorgestur',
    'Þorgísl',
    'Þorgnýr',
    'Þormundur',
    'Þórhaddur',
    'Þórörn',
    'Þrastar',
    'Öndólfur',
    'Abel',
    'Aðalborgar',
    'Amos',
    'Anes',
    'Angi',
    'Aríel',
    'Arndór',
    'Arnfreyr',
    'Arnleifur',
    'Arnúlfur',
    'Atlas',
    'Auðbert',
    'Auðólfur',
    'Álfþór',
    'Árbjörn',
    'Árelíus',
    'Ásbergur',
    'Ásmar',
    'Ástvin',
    'Baugur',
    'Beitir',
    'Berg',
    'Berghreinn',
    'Bersi',
    'Bjargar',
    'Bjargþór',
    'Bjarnólfur',
    'Bjarnsteinn',
    'Bæringur',
    'Daggeir',
    'Dagmann',
    'Dagþór',
    'Dalbert',
    'Daníval',
    'Dónald',
    'Edílon',
    'Elberg',
    'Elinór',
    'Elínór',
    'Elli',
    'Embrek',
    'Engiljón',
    'Engill',
    'Erlar',
    'Esjar',
    'Eyberg',
    'Eyvar',
    'Fanngeir',
    'Ferdínand',
    'Finnjón',
    'Finnlaugur',
    'Fjölvar',
    'Fjörnir',
    'Flórent',
    'Forni',
    'Freymóður',
    'Funi',
    'Gamalíel',
    'Garri',
    'Geisli',
    'Gerðar',
    'Gilmar',
    'Gnúpur',
    'Gnýr',
    'Grímlaugur',
    'Grímólfur',
    'Grímúlfur',
    'Guðfreður',
    'Guðvin',
    'Gunndór',
    'Gunngeir',
    'Gunnhallur',
    'Hafni',
    'Hallkell',
    'Hallþór',
    'Harrý',
    'Hartvig',
    'Heiðbert',
    'Heiðlindur',
    'Heiðrekur',
    'Hemmert',
    'Hergill',
    'Hergils',
    'Herleifur',
    'Hersveinn',
    'Hervin',
    'Hildar',
    'Hildibrandur',
    'Hildimar',
    'Hildingur',
    'Himri',
    'Hjarnar',
    'Hjálmþór',
    'Hnikar',
    'Holgeir',
    'Holti',
    'Hólm',
    'Hólmþór',
    'Hrafnar',
    'Hrafnbergur',
    'Hraunar',
    'Hrollaugur',
    'Hróðvar',
    'Hrói',
    'Húnn',
    'Hængur',
    'Ingberg',
    'Ingimagn',
    'Ísgeir',
    'Jakop',
    'Játmundur',
    'Jochum',
    'Jóann',
    'Jómar',
    'Jörmundur',
    'Jörvi',
    'Kalmann',
    'Kalmar',
    'Karkur',
    'Kató',
    'Kiljan',
    'Kjaran',
    'Kolfinnur',
    'Kolgrímur',
    'Kolviður',
    'Kristall',
    'Laufar',
    'Líni',
    'Ljótur',
    'Manfred',
    'Markþór',
    'Marthen',
    'Mías',
    'Mjölnir',
    'Myrkvi',
    'Natanael',
    'Neptúnus',
    'Nóni',
    'Oddfreyr',
    'Oddþór',
    'Olivert',
    'Rasmus',
    'Reinar',
    'Rómeó',
    'Rósenberg',
    'Rósi',
    'Rósinberg',
    'Rósinkrans',
    'Rúben',
    'Rúdólf',
    'Rögnvar',
    'Safír',
    'Sakarías',
    'Salmar',
    'Seifur',
    'Seimur',
    'Sigbert',
    'Signar',
    'Sigur',
    'Siguroddur',
    'Sólvin',
    'Steinbergur',
    'Steinmóður',
    'Stirnir',
    'Svali',
    'Svangeir',
    'Sýrus',
    'Sæbergur',
    'Sælaugur',
    'Sörli',
    'Thorberg',
    'Tindri',
    'Tímon',
    'Tístran',
    'Tjörfi',
    'Tóbías',
    'Tór',
    'Úlfhéðinn',
    'Úlfljótur',
    'Úranus',
    'Valter',
    'Valves',
    'Vatnar',
    'Végeir',
    'Vilbogi',
    'Vilbrandur',
    'Víðar',
    'Vígmundur',
    'Vígsteinn',
    'Zakaría',
    'Zóphonías',
    'Þangbrandur',
    'Þeyr',
    'Þjóðólfur',
    'Þorlaugur',
    'Þórlaugur',
    'Þórormur',
    'Ölnir',
    'Adel',
    'Aðalmundur',
    'Agni',
    'Alexíus',
    'Annas',
    'Arnald',
    'Artúr',
    'Auðbjörn',
    'Álfur',
    'Árbjartur',
    'Ási',
    'Áslákur',
    'Ásólfur',
    'Bekan',
    'Benóní',
    'Betúel',
    'Bjarmar',
    'Bjarnfreður',
    'Bjartmann',
    'Bjartþór',
    'Björgmundur',
    'Cecil',
    'Cýrus',
    'Damjan',
    'Danelíus',
    'Dómald',
    'Drengur',
    'Dvalinn',
    'Dýrmundur',
    'Elentínus',
    'Fjólmundur',
    'Flóvent',
    'Freygarður',
    'Friðmundur',
    'Garibaldi',
    'Geirarður',
    'Gottsveinn',
    'Grímar',
    'Guðveigur',
    'Gunnleifur',
    'Gutti',
    'Gýmir',
    'Hagbarður',
    'Hámundur',
    'Heiðberg',
    'Heiðmundur',
    'Hildimundur',
    'Hildiþór',
    'Hjörtþór',
    'Hlér',
    'Hrappur',
    'Húgó',
    'Jes',
    'Jesper',
    'Kaleb',
    'Karles',
    'Kasper',
    'Ketilbjörn',
    'Kjalar',
    'Klængur',
    'Kort',
    'Kristdór',
    'Krister',
    'Lauritz',
    'Leo',
    'Leonhard',
    'Lórens',
    'Manúel',
    'Mari',
    'Marjón',
    'Marten',
    'Mekkinó',
    'Methúsalem',
    'Moritz',
    'Muggur',
    'Níls',
    'Oddi',
    'Oddleifur',
    'Oddvar',
    'Otri',
    'Reidar',
    'Rósinkar',
    'Sandri',
    'Sigmann',
    'Sigurbaldur',
    'Sigurliði',
    'Sigurlinni',
    'Sigurmann',
    'Sívar',
    'Sjafnar',
    'Smyrill',
    'Snjólaugur',
    'Snævarr',
    'Sólbjartur',
    'Sólver',
    'Stanley',
    'Steinmar',
    'Styr',
    'Sveinmar',
    'Sæberg',
    'Sævin',
    'Sölmundur',
    'Trostan',
    'Trúmann',
    'Úlrik',
    'Valmar',
    'Veigur',
    'Vigri',
    'Vilbert',
    'Vígþór',
    'Vörður',
    'Ymir',
    'Zophanías',
    'Þjóðbjörn',
    'Þorberg',
    'Þórhalli',
    'Þrymur',
    'Þyrnir',
    'Ögri',
    'Örlaugur',
    'Aðalbergur',
    'Arnbergur',
    'Arnsteinn',
    'Álfgrímur',
    'Beinteinn',
    'Bjarnleifur',
    'Boði',
    'Dagnýr',
    'Dalmar',
    'Ebeneser',
    'Eðvar',
    'Einþór',
    'Eldjárn',
    'Eyleifur',
    'Fannberg',
    'Freymar',
    'Freyþór',
    'Friðlaugur',
    'Friðsteinn',
    'Geirharður',
    'Guðröður',
    'Guðþór',
    'Haddur',
    'Hallgeir',
    'Herbjörn',
    'Hólmbert',
    'Höður',
    'Ingileifur',
    'Ingiþór',
    'Jenni',
    'Jómundur',
    'Jónþór',
    'Jósafat',
    'Jörvar',
    'Kolbjörn',
    'Kornelíus',
    'Kristfinnur',
    'Krummi',
    'Kveldúlfur',
    'Leópold',
    'Leví',
    'Lindberg',
    'Loki',
    'Lórenz',
    'Maggi',
    'Magnþór',
    'Manfreð',
    'Matti',
    'Max',
    'Mörður',
    'Nikolas',
    'Oddbergur',
    'Oddbjörn',
    'Októ',
    'Ósvaldur',
    'Rikharð',
    'Rólant',
    'Röðull',
    'Salmann',
    'Sigríkur',
    'Sigurbjarni',
    'Sigurbrandur',
    'Sigurlás',
    'Sigurleifur',
    'Sigurmar',
    'Sigurmon',
    'Stormur',
    'Sveinjón',
    'Sveinlaugur',
    'Sveinþór',
    'Tinni',
    'Týr',
    'Valdór',
    'Vermundur',
    'Vébjörn',
    'Willard',
    'Yngvar',
    'Zakarías',
    'Þorleikur',
    'Þórgrímur',
    'Ævarr',
    'Aage',
    'Adólf',
    'Aðalberg',
    'Ali',
    'Annel',
    'Antoníus',
    'Arngeir',
    'Álfgeir',
    'Ástbjörn',
    'Berent',
    'Bjarkar',
    'Brimir',
    'Dýri',
    'Einvarður',
    'Elíeser',
    'Erpur',
    'Garpur',
    'Grímkell',
    'Gyrðir',
    'Hafberg',
    'Hagalín',
    'Haki',
    'Hallmann',
    'Harri',
    'Hektor',
    'Hervar',
    'Hólmkell',
    'Hugo',
    'Jack',
    'Jarl',
    'Játvarður',
    'Katarínus',
    'Kópur',
    'Kristens',
    'Liljar',
    'Mattías',
    'Metúsalem',
    'Ósvald',
    'Rósar',
    'Rósberg',
    'Sigjón',
    'Sigurgestur',
    'Skeggi',
    'Steinberg',
    'Sævald',
    'Unndór',
    'Vernharð',
    'Viljar',
    'Vöggur',
    'Wilhelm',
    'Þiðrik',
    'Þorvar',
    'Þórmar',
    'Þrúðmar',
    'Abraham',
    'Arent',
    'Arnviður',
    'Arthúr',
    'Áslaugur',
    'Bertel',
    'Bjarnfinnur',
    'Blængur',
    'Blær',
    'Bruno',
    'Dagfinnur',
    'Eldur',
    'Elimar',
    'Elis',
    'Friðbergur',
    'Friðvin',
    'Gizur',
    'Glúmur',
    'Guðbjarni',
    'Heikir',
    'Henrý',
    'Hermóður',
    'Ísidór',
    'Jónsteinn',
    'Jósúa',
    'Júlí',
    'Kolmar',
    'Kristbergur',
    'Ludvig',
    'Meyvant',
    'Móses',
    'Nikolai',
    'Rafnkell',
    'Rannver',
    'Reynald',
    'Samson',
    'Sigsteinn',
    'Sigurhjörtur',
    'Sigvarður',
    'Steini',
    'Steinólfur',
    'Uggi',
    'Ulrich',
    'Valsteinn',
    'Adrían',
    'Arnlaugur',
    'Auðbergur',
    'Ástgeir',
    'Bergmundur',
    'Bjarnar',
    'Bjarnhéðinn',
    'Eilífur',
    'Eldar',
    'Falur',
    'Fritz',
    'Geirlaugur',
    'Gerald',
    'Gils',
    'Guðráður',
    'Gunnólfur',
    'Hallberg',
    'Hergeir',
    'Herluf',
    'Hlini',
    'Húbert',
    'Húnbogi',
    'Jósavin',
    'Kalman',
    'Leiknir',
    'Marís',
    'Nicolas',
    'Sophus',
    'Svan',
    'Svanbjörn',
    'Svanþór',
    'Sváfnir',
    'Sveinberg',
    'Tandri',
    'Tobías',
    'Unnþór',
    'Valentínus',
    'Þórlindur',
    'Ölvir',
    'Arilíus',
    'Arnkell',
    'Ástmundur',
    'Ebenezer',
    'Emanúel',
    'Friðmar',
    'Geirfinnur',
    'Herjólfur',
    'Hlíðar',
    'Hreimur',
    'Hróar',
    'Joshua',
    'Jóngeir',
    'Norbert',
    'Reynar',
    'Sebastían',
    'Sesar',
    'Sigurkarl',
    'Sigurvaldi',
    'Skæringur',
    'Steinbjörn',
    'Veturliði',
    'Willum',
    'Þórgnýr',
    'Þórleifur',
    'Alfons',
    'Arnmundur',
    'Arnoddur',
    'Arnold',
    'Bernódus',
    'Dan',
    'Dofri',
    'Draupnir',
    'Edvin',
    'Fáfnir',
    'Ferdinand',
    'Francis',
    'Fylkir',
    'Greipur',
    'Gustav',
    'Hallmundur',
    'Hávar',
    'Heiðmar',
    'Hersteinn',
    'Ingibjartur',
    'Júníus',
    'Kaj',
    'Kristgeir',
    'Kristian',
    'Kristmar',
    'Lúðvíg',
    'Ormar',
    'Ormur',
    'Sigdór',
    'Sigurgrímur',
    'Starkaður',
    'Starri',
    'Svafar',
    'Svanlaugur',
    'Uni',
    'Alfred',
    'Alvin',
    'Baltasar',
    'Danival',
    'Dúi',
    'Esra',
    'Evert',
    'Friðberg',
    'Friðþór',
    'Gretar',
    'Hallfreður',
    'Hannibal',
    'Hálfdan',
    'Hersir',
    'Huldar',
    'Ingvaldur',
    'Ísólfur',
    'Jósteinn',
    'Leonard',
    'Otti',
    'Reginn',
    'Rolf',
    'Rútur',
    'Sigurhans',
    'Snær',
    'Styrkár',
    'Sævaldur',
    'Valberg',
    'Vilbergur',
    'Alvar',
    'Edgar',
    'Eymar',
    'Filip',
    'Finn',
    'Flemming',
    'Franklín',
    'Gaukur',
    'Hallmar',
    'Hallsteinn',
    'Hendrik',
    'Hermundur',
    'Húni',
    'Kristþór',
    'Márus',
    'Oddsteinn',
    'Olaf',
    'Snjólfur',
    'Vagn',
    'Þórbergur',
    'Þórsteinn',
    'Anders',
    'Bjargmundur',
    'Goði',
    'Guðberg',
    'Hólmgrímur',
    'Júlían',
    'Klemenz',
    'Ólíver',
    'Per',
    'Siguróli',
    'Skírnir',
    'Sólberg',
    'Vikar',
    'Vilmar',
    'Angantýr',
    'Arnljótur',
    'Askur',
    'Ástmar',
    'Bernharður',
    'Brimar',
    'Burkni',
    'Elliði',
    'Gautur',
    'Gilbert',
    'Lúther',
    'Marías',
    'Rósant',
    'Rósmundur',
    'Rúrik',
    'Sigurmundur',
    'Sören',
    'Tindur',
    'Valmundur',
    'Anthony',
    'Bergmann',
    'Broddi',
    'Brynjúlfur',
    'Brynleifur',
    'Ernst',
    'Friðleifur',
    'Hjálmur',
    'Jafet',
    'Jónbjörn',
    'Kristberg',
    'Kristvin',
    'Marel',
    'Narfi',
    'Pjetur',
    'Ríkharð',
    'Skorri',
    'Ásberg',
    'Bæring',
    'Filippus',
    'Frans',
    'Gabriel',
    'Johan',
    'Leon',
    'Maron',
    'Mímir',
    'Óttarr',
    'Rökkvi',
    'Salvar',
    'Sigurbjartur',
    'Sigurgísli',
    'Sigurlaugur',
    'Snæþór',
    'Sófus',
    'Tyrfingur',
    'Valbjörn',
    'Völundur',
    'Ölver',
    'Önundur',
    'Brjánn',
    'Salómon',
    'Ásvaldur',
    'Bent',
    'Björgólfur',
    'Bryngeir',
    'Diðrik',
    'Geirmundur',
    'Guðvarður',
    'Hróðmar',
    'Ingiberg',
    'Jóakim',
    'Karvel',
    'Stefan',
    'Steinarr',
    'Vernharður',
    'Þengill',
    'Þórmundur',
    'Einir',
    'Hlífar',
    'Jónmundur',
    'Sigurfinnur',
    'Harry',
    'Ingjaldur',
    'Klemens',
    'Rafael',
    'Randver',
    'Skapti',
    'Þrándur',
    'Bernhard',
    'Björgúlfur',
    'Fjalar',
    'Hávarður',
    'Karel',
    'Thor',
    'Zophonías',
    'Allan',
    'Bjarnþór',
    'Edvard',
    'Eric',
    'Gottskálk',
    'Lúkas',
    'Rafnar',
    'Reimar',
    'Sigfinnur',
    'Sigurberg',
    'Sigurdór',
    'Stígur',
    'Andreas',
    'Ásþór',
    'Ástráður',
    'Benoný',
    'Christopher',
    'Guðleifur',
    'Hallvarður',
    'Hjálmtýr',
    'Hringur',
    'Ingibjörn',
    'Janus',
    'Nils',
    'Rudolf',
    'Sigbjörn',
    'Skjöldur',
    'Enok',
    'Flóki',
    'Friðbert',
    'Gunnbjörn',
    'Illugi',
    'Sæbjörn',
    'Walter',
    'Bernharð',
    'Finnbjörn',
    'Hartmann',
    'Marvin',
    'Össur',
    'Búi',
    'Franz',
    'Henning',
    'Kristleifur',
    'Tjörvi',
    'Vífill',
    'Huginn',
    'Patrick',
    'Siggeir',
    'Sólmundur',
    'Arnfinnur',
    'Benóný',
    'Bjarmi',
    'Christian',
    'Hallbjörn',
    'Sólon',
    'Þormar',
    'Auðun',
    'Bessi',
    'Brandur',
    'Carl',
    'Fróði',
    'Henrik',
    'Hólmsteinn',
    'Hróbjartur',
    'Maríus',
    'Snævar',
    'Stefnir',
    'Eymundur',
    'Grettir',
    'Harald',
    'Sigursveinn',
    'Valþór',
    'Elí',
    'Ívan',
    'Lars',
    'Njörður',
    'Valgarð',
    'Bóas',
    'Nói',
    'Sturlaugur',
    'Ámundi',
    'Henry',
    'Númi',
    'Svanberg',
    'Bergvin',
    'Felix',
    'Hreggviður',
    'Jason',
    'Peter',
    'Sebastian',
    'William',
    'Áki',
    'Bolli',
    'Edward',
    'Hólmgeir',
    'Tumi',
    'Arnbjörn',
    'Arthur',
    'Freysteinn',
    'Kormákur',
    'Patrik',
    'Birnir',
    'Eðvald',
    'Gústav',
    'Ísar',
    'Kristjón',
    'Natan',
    'Sumarliði',
    'Víglundur',
    'Arinbjörn',
    'Borgar',
    'Guðmar',
    'Skafti',
    'Örnólfur',
    'Barði',
    'Frank',
    'Lýður',
    'Veigar',
    'Ernir',
    'Friðfinnur',
    'Guttormur',
    'Vésteinn',
    'Áskell',
    'Valdemar',
    'Ýmir',
    'Aðalgeir',
    'Engilbert',
    'Martin',
    'Þormóður',
    'Þorri',
    'Sigurbergur',
    'Jörundur',
    'Adolf',
    'Erik',
    'Oddgeir',
    'Ófeigur',
    'Sigurpáll',
    'Þóroddur',
    'Ástvaldur',
    'Borgþór',
    'Guðmann',
    'Jörgen',
    'Ríkarður',
    'Friðþjófur',
    'Frosti',
    'Guðbergur',
    'Gunnsteinn',
    'Vilmundur',
    'Olgeir',
    'Óliver',
    'Þorfinnur',
    'Bjartmar',
    'Jósep',
    'Kristmann',
    'Aðalbjörn',
    'Hugi',
    'Ingibergur',
    'Thomas',
    'Ingþór',
    'Guðgeir',
    'Ketill',
    'Flosi',
    'Gunnþór',
    'Eyvindur',
    'Vilberg',
    'Bergsveinn',
    'Jan',
    'Herbert',
    'Hólmar',
    'Richard',
    'Valgarður',
    'Sighvatur',
    'Darri',
    'Hjörvar',
    'Arnaldur',
    'Dagbjartur',
    'Fjölnir',
    'Frímann',
    'Ögmundur',
    'Örlygur',
    'John',
    'Bergsteinn',
    'Kristbjörn',
    'Víkingur',
    'Friðbjörn',
    'Hlöðver',
    'Nikulás',
    'Börkur',
    'Daniel',
    'Margeir',
    'Oliver',
    'Nökkvi',
    'Njáll',
    'Unnsteinn',
    'Eðvarð',
    'Elfar',
    'Þorgils',
    'Bárður',
    'Hálfdán',
    'Ísleifur',
    'Tristan',
    'Gissur',
    'Michael',
    'Sigurvin',
    'Knútur',
    'Pálmar',
    'Guðfinnur',
    'Hrólfur',
    'Yngvi',
    'Sæþór',
    'Victor',
    'Valtýr',
    'Viggó',
    'Gauti',
    'Patrekur',
    'Jóel',
    'Friðgeir',
    'Magni',
    'Ríkharður',
    'Jósef',
    'Jónatan',
    'Theodór',
    'Þorbergur',
    'Úlfur',
    'Kristmundur',
    'Vilhelm',
    'Héðinn',
    'Leó',
    'Sigursteinn',
    'Theódór',
    'Arngrímur',
    'Breki',
    'Elís',
    'Þorvarður',
    'Ársæll',
    'Bjartur',
    'Bogi',
    'Friðjón',
    'Teitur',
    'Ástþór',
    'Högni',
    'Þorlákur',
    'Þórólfur',
    'Þorgrímur',
    'Sigurþór',
    'Hilmir',
    'Loftur',
    'Már',
    'Runólfur',
    'Ásgrímur',
    'Almar',
    'Indriði',
    'Gústaf',
    'Níels',
    'Ottó',
    'Sigvaldi',
    'Máni',
    'Snæbjörn',
    'Hallur',
    'Alex',
    'Ásbjörn',
    'Sigtryggur',
    'Benjamín',
    'Ellert',
    'Höskuldur',
    'Örvar',
    'Guðbrandur',
    'Erling',
    'Styrmir',
    'Hjörleifur',
    'Hreiðar',
    'Hafliði',
    'Hrannar',
    'Samúel',
    'Skarphéðinn',
    'Guðbjartur',
    'Alfreð',
    'Eysteinn',
    'Freyr',
    'Marinó',
    'Ingimundur',
    'Markús',
    'Víðir',
    'Böðvar',
    'Hrafnkell',
    'Logi',
    'Sturla',
    'Steinn',
    'Auðunn',
    'Georg',
    'Rögnvaldur',
    'Konráð',
    'Lúðvík',
    'Óttar',
    'Bergþór',
    'Elmar',
    'Þorbjörn',
    'Torfi',
    'Hans',
    'Steindór',
    'Vigfús',
    'Arnþór',
    'Sigþór',
    'Guðbjörn',
    'Þráinn',
    'Þorleifur',
    'Eiður',
    'Ingvi',
    'Grímur',
    'Sigmundur',
    'Rafn',
    'Úlfar',
    'Jökull',
    'Orri',
    'Svanur',
    'Finnbogi',
    'Símon',
    'Sigmar',
    'Gabríel',
    'Hinrik',
    'Steinþór',
    'Marteinn',
    'Óðinn',
    'Ævar',
    'Unnar',
    'Erlendur',
    'Erlingur',
    'Sölvi',
    'Albert',
    'Ingimar',
    'Ægir',
    'Ármann',
    'Gestur',
    'Smári',
    'Jens',
    'Hreinn',
    'Kolbeinn',
    'Þorkell',
    'Bergur',
    'Brynjólfur',
    'Ásmundur',
    'Valgeir',
    'Hjálmar',
    'Sigfús',
    'Þorgeir',
    'Leifur',
    'Vignir',
    'Daði',
    'Finnur',
    'Sæmundur',
    'Sigurgeir',
    'Steingrímur',
    'Agnar',
    'Þórhallur',
    'Oddur',
    'Geir',
    'Pálmi',
    'Fannar',
    'Andrés',
    'Heimir',
    'Baldvin',
    'Valur',
    'Þröstur',
    'Heiðar',
    'Trausti',
    'Óli',
    'Elías',
    'Sigurbjörn',
    'Hafþór',
    'Eggert',
    'Sveinbjörn',
    'Gylfi',
    'Eyjólfur',
    'Þór',
    'Hallgrímur',
    'Júlíus',
    'Svavar',
    'Lárus',
    'Viðar',
    'Hrafn',
    'Bragi',
    'Hannes',
    'Guðlaugur',
    'Skúli',
    'Þorvaldur',
    'Gunnlaugur',
    'Aðalsteinn',
    'Hjörtur',
    'Hermann',
    'Adam',
    'Hákon',
    'Hjalti',
    'Ingi',
    'Mikael',
    'Vilhjálmur',
    'Valdimar',
    'Steinar',
    'Emil',
    'Tryggvi',
    'Grétar',
    'Þórir',
    'Axel',
    'Eyþór',
    'Þórarinn',
    'Garðar',
    'Birkir',
    'Hafsteinn',
    'Anton',
    'Arnór',
    'Ívar',
    'Matthías',
    'Ingólfur',
    'Reynir',
    'Dagur',
    'Snorri',
    'Jakob',
    'Ingvar',
    'Ísak',
    'Ari',
    'Ómar',
    'Örn',
    'Sverrir',
    'Hlynur',
    'Elvar',
    'Guðni',
    'Sævar',
    'Björgvin',
    'Sindri',
    'Eiríkur',
    'Egill',
    'Jónas',
    'Rúnar',
    'Hörður',
    'Kári',
    'Kristófer',
    'Hilmar',
    'Þórður',
    'Baldur',
    'Friðrik',
    'Sigurjón',
    'Kjartan',
    'Atli',
    'Haraldur',
    'Benedikt',
    'Brynjar',
    'Ásgeir',
    'Karl',
    'Ágúst',
    'Jóhannes',
    'Haukur',
    'Tómas',
    'Bjarki',
    'Viktor',
    'Alexander',
    'Andri',
    'Davíð',
    'Birgir',
    'Óskar',
    'Páll',
    'Róbert',
    'Sveinn',
    'Aron',
    'Guðjón',
    'Þorsteinn',
    'Gísli',
    'Ragnar',
    'Kristinn',
    'Daníel',
    'Pétur',
    'Halldór',
    'Helgi',
    'Bjarni',
    'Árni',
    'Arnar',
    'Björn',
    'Jóhann',
    'Stefán',
    'Magnús',
    'Kristján',
    'Einar',
    'Ólafur',
    'Gunnar',
    'Guðmundur',
    'Sigurður',
    'Jón'
];