import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/lab/Slider';

const styles = {
    root: {
        margin: '18px 0 0 0',
        width: '95%',
    },
    slider: {
        padding: '22px 0px',
        '.button': {
            background: "red"
        }
    },
    sliderLabel: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "0.875rem",
        fontWeight: "400",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        lineHeight: "1.46429em",
        margin: 0,
        display: "block"
    }
};

class randomNameInput extends React.Component {
    handleChange = (event, value) => {
        this.props.card.updateRandomness(this.props.cardIndex, value)
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <style jsx="true">{`
                     .Slider button{
                          background-color: ` + this.props.color + `;
                      }
                      .Slider div div {
                          background-color: ` + this.props.color + `;
                      }
                 `}
                </style>
                <p className={classes.sliderLabel} id="sliderLabel">Algengi nafns</p>
                <Slider
                    className={"Slider"}
                    style={{color: this.props.color}}
                    classes={{container: classes.slider}}
                    value={this.props.card.randomness}
                    aria-labelledby="sliderLabel"
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

randomNameInput.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(randomNameInput);
