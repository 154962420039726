import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import InputWrapper from './InputWrapper'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles'
import GenderPicker from './GenderPicker';
import NameHistory from "./NameHistory";
import NameDisplay from "./NameDisplay";
import CenterIconMenu from "./CenterIconMenu";
import CookieConsent, {Cookies} from "react-cookie-consent";

const styles = theme => ({
    appBar: {
        top: 0,
    },
    title: {
        color: "white",
        textDecoration: "none",
        fontSize: "1.3125rem",
        fontWeight: "500",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        lineHeight: "1.16667em",
        cursor: "pointer",
    },
    main: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    icon: {
        marginRight: theme.spacing.unit * 2,
        fontSize: "2.5rem",
        cursor: 'pointer'
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    cardGrid: {
        padding: 0,
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        textAlign: "center",
        backgroundColor: theme.palette.background.paper,

    },
    footerText: {
        padding: theme.spacing.unit * 2,
        color: "rgba(0, 0, 0, 0.4)",
        fontSize: "1rem",
        fontWeight: "500",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        lineHeight: "1.75",
        letterSpacing: "0.00938em"
    },
    footerLink: {
        color: "rgba(0, 0, 0, 0.3)"
    },
    footerLine: {
        margin: "0px",
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
});


function Template(props) {
    const {classes} = props;
    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar position="static" className={classes.appBar} style={{background: props.data.template.color}}>
                <Toolbar>
                    <h1 className={classes.title} onClick={() => window.location = "/"}> Hvað á barnið að heita? </h1>
                </Toolbar>
            </AppBar>
            <main className={classes.main}>
                <CenterIconMenu data={props.data}/>

                <NameDisplay cards={props.data.cards} toggleInputType={props.data.toggleInputType}/>
                <GenderPicker color={props.data.template.color} gender={props.data.gender}/>

                <div className={classNames(classes.layout, classes.cardGrid)}>
                    <Grid container justify={'center'} spacing={8}>
                        {props.data.cards.map((card, cardIndex) => (
                            <InputWrapper color={props.data.template.color} key={cardIndex} card={card} cardIndex={cardIndex}/>
                        ))}
                    </Grid>
                </div>
                <NameHistory toggleNameHistoryHeart={props.data.toggleNameHistoryHeart} rollBack={props.data.rollBack} nameHistory={props.data.nameHistory}/>
            </main>

            <footer className={classes.footer}>
                <h5 className={classes.footerText}>
                    <p className={classes.footerLine}>Hvað á barnið að heita?</p>
                    <p className={classes.footerLine}>[Búið til af Vefurunum E&E]</p>
                    <p className={classes.footerLine}><a className={classes.footerLink} href="mailto:upplysingar@hvadabarnidadheita.is">Hafðu samband </a></p>
                </h5>
            </footer>

            <CookieConsent buttonText="Í lagi" buttonStyle={{backgroundColor: props.data.template.color, color: "white", fontSize: "13px"}}>
                Hvað á barnið að heita notar vefkökur (e. cookies) til að bæta notendaupplifun á vefsíðunni.
            </CookieConsent>
        </React.Fragment>
    );
}

Template.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Template);