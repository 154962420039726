import React from 'react';
import PropTypes from 'prop-types';
import ShareIcon from '@material-ui/icons/Share';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {withStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import {FaTwitter} from 'react-icons/fa';
import {FaFacebook} from 'react-icons/fa';
import {FaEnvelope} from 'react-icons/fa';
import {ShareButtonRoundSquare, ShareBlockStandard} from 'react-custom-share';
import MenuList from "@material-ui/core/MenuList/MenuList";

const styles = () => ({
    shareIcon: {
        fontSize: "1.8rem",
    }
});

class SocialMediaMenu extends React.Component {
    state = {
        open: false,
    };

    shareBlockProps = () => {
        return {
            url: this.props.shareMenuUrl(),
            button: ShareButtonRoundSquare,
            buttons: [
                {network: 'Twitter', icon: FaTwitter},
                {network: 'Facebook', icon: FaFacebook},
                {network: 'Email', icon: FaEnvelope},
            ],
            text: this.props.getNameOnDisplay(),
            longtext: ``,
        }
    };

    handleToggle = () => {
        this.setState({open: !this.state.open});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;
        const {open} = this.state;

        return (
            <React.Fragment>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <React.Fragment>
                        <IconButton
                            buttonRef={node => this.anchorEl = node}
                            aria-owns={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggle}
                        >
                            <ShareIcon className={classes.shareIcon}/>
                        </IconButton>

                        <Popper open={open} anchorEl={this.anchorEl} transition style={{zIndex: 100}}>
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    id="menu-list-grow"
                                    style={{transformOrigin: placement === 'bottom' ? 'right top' : 'center bottom'}}
                                >
                                    <Paper>
                                        <MenuList>
                                            <ShareBlockStandard {...this.shareBlockProps()} />;
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </React.Fragment>
                </ClickAwayListener>
            </React.Fragment>
        );
    }
}

SocialMediaMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialMediaMenu);