import React, {Component} from 'react'
import Template from "./components/Template"
import update from 'react-addons-update';
import gaussian from 'gaussian';
import * as ReactGA from 'react-ga';
import * as queryString from 'query-string';
import boyFirstNamesOrdered from "./names/boyFirstNamesOrdered";
import boyMiddleNamesOrdered from "./names/boyMiddleNamesOrdered";
import girlMiddleNamesOrdered from "./names/girlMiddleNamesOrdered";
import girlFirstNamesOrdered from "./names/girlFirstNamesOrdered";
import {createHashHistory} from 'history';

export const history = createHashHistory();

class Index extends Component {
    refreshGoingOn = false;

    constructor(props) {
        super(props);
        this.initGA();
        this.state = {
            cards: [this.createCard("Fornafn", false, "RandomNameInput", 80)],
            toggleInputType: this.toggleInputType,
            nameHistory: this.initNameHistory(),
            heartCheck: false,
            createNewName: this.createNewName,
            gender: {option: "Other", updateGender: this.updateGender},
            getNameOnDisplay: this.getNameOnDisplay,
            shareMenuUrl: () => "https://hvadabarnidadheita.is/#/" + this.generateShareUrl(),
            toggleNameHistoryHeart: this.toggleNameHistoryHeart,
            rollBack: this.rollBackNameFromHistory,
            template: {color: this.getRandomColor()}
        };
    }

    componentDidMount() {
        this.initTheName();
    }

    //==============================================================================
    //================================= CARDS ======================================
    //==============================================================================
    initCards = () => {
        this.setState({cards: [this.createCard("Fornafn", false, "RandomNameInput", 80), this.createCard("Millinafn", true, "RandomNameInput", 80), this.createCard("Eftirnafn", false, "CustomNameInput", 80)]},
            () => this.createNewName());
    };

    createCard = (title, deletable, type, randomness, input) => {
        return {
            title: title,
            deletable: deletable,
            type: type,
            randomness: randomness,
            input: input ? input : '',
            updateInput: this.updateInput,
            updateRandomness: this.updateRandomness,
            menuItems: this.createMenuForCard(type, title, deletable),
        };
    };

    addNewCard = (cardIndex) => {
        let newCard = {};
        if (cardIndex >= this.state.cards.length - 1) {
            newCard = this.createCard("Eftirnafn", true, this.state.cards[cardIndex].type, 80);
        } else {
            newCard = this.createCard("Millinafn", true, this.state.cards[cardIndex].type, 80);
        }
        this.state.cards.splice(cardIndex + 1, 0, newCard);
        this.setState({cards: this.state.cards});
    };

    deleteCard = (cardIndex) => {
        if (this.state.cards[cardIndex].deletable) {
            this.state.cards.splice(cardIndex, 1);
            this.setState({cards: this.state.cards});
        }
    };

    updateInput = (cardIndex, input) => {
        this.setState({
            cards: update(this.state.cards, {[cardIndex]: {input: {$set: this.cleanInput(input)}}})
        });
    };

    updateRandomness = (cardIndex, newRandomness) => {
        this.setState({
            cards: update(this.state.cards, {[cardIndex]: {randomness: {$set: newRandomness}}})
        });
    };

    getNameOnDisplay = () => {
        return this.state.cards.map((card) => (card.input)).join(" ").trim();
    };

    //=============================== CARD MENU ====================================
    createMenuForCard = (type, title, deletable) => {
        let menuItems = [];

        if (deletable) {
            menuItems.push({text: 'Fornafn/Millinafn', onClick: this.toggleCardTitle});
        }
        menuItems.push({text: type === "RandomNameInput" ? 'Velja nafn' : 'Nafn af handahófi', onClick: this.toggleInputType});
        menuItems.push({text: 'Nýtt spjald', onClick: this.addNewCard});

        if (deletable) {
            menuItems.push({text: 'Eyða spjaldi', onClick: this.deleteCard});
        }
        return menuItems
    };

    toggleInputType = (cardIndex) => {
        const newType = this.state.cards[cardIndex].type === "RandomNameInput" ? "CustomNameInput" : "RandomNameInput";
        setTimeout(() =>
            this.setState({
                cards: update(this.state.cards, {
                    [cardIndex]: {
                        type: {$set: newType},
                        menuItems: {$set: this.createMenuForCard(newType, this.state.cards[cardIndex].title, this.state.cards[cardIndex].deletable)}
                    }
                })
            }), 100);
    };

    toggleCardTitle = (cardIndex) => {
        const currentTitle = this.state.cards[cardIndex].title;
        if (currentTitle !== "Eftirnafn" && cardIndex !== 0) {
            const newTitle = this.state.cards[cardIndex].title === "Fornafn" ? "Millinafn" : "Fornafn";

            setTimeout(() =>
                this.setState({
                    cards: update(this.state.cards, {
                        [cardIndex]: {
                            title: {$set: newTitle},
                        }
                    })
                }), 200);
        }
    };

    //==============================================================================
    //============================ NAME GENERATION =================================
    //==============================================================================
    initTheName = () => {
        try {
            const parsedQuery = queryString.parse(history.location.search);
            if (parsedQuery.n && parsedQuery.c && parsedQuery.n.trim().length > 0) {
                let parsedNameList = parsedQuery.n.split("-");
                let parsedCardList = parsedQuery.c.split("-");
                if (parsedCardList.length === parsedNameList.length) {
                    this.initCardsFromQueryString(parsedNameList, parsedCardList, parsedQuery.g);
                    return;
                }
            }
        } catch (e) {
            //Don't care
        }
        this.initCards();
    };

    createNewName = () => {
        if (!this.refreshGoingOn) {
            this.randomizeAllCardsNorm();
        }
    };

    randomizeAllCardsNorm = () => {
        this.refreshGoingOn = true;
        this.updateHistory();

        let gender = this.getGender();
        let timeout = 0;
        for (const [cardIndex, card] of this.state.cards.entries()) {
            timeout += 100;
            setTimeout(() => {
                this.randomizeIfApplicableNorm(card, gender, cardIndex);
                if (this.state.cards.length - 1 === cardIndex) {
                    this.randomizeFinished()
                }
            }, timeout);
        }
    };

    randomizeFinished = () => {
        const name = this.state.cards.map((card) => (card.input)).join(" ").trim();
        this.updateQueryString(name);
        this.sendGAEventForNewName(name);
        this.refreshGoingOn = false;
        this.updateHistory();
    };

    randomizeIfApplicableNorm = (card, gender, cardIndex) => {
        if (card.type === "RandomNameInput") {
            let listToProcess = this.getNameListsToUseNorm(card, gender);
            let randomName = this.getNormName(listToProcess, card.randomness / 100);
            this.setState({
                cards: update(this.state.cards, {[cardIndex]: {input: {$set: randomName}}}),
            });
        }
    };

    getNormName = (listToRandom, randomness, sigma = -1) => {
        let mean = listToRandom.length * randomness;
        if (sigma === -1) {
            //TODO maybe have sigma change based on mean, e.g. larger sigma if at either end
            sigma = 200;
        }
        let distribution = gaussian(mean, sigma * sigma);
        let idx = -1;
        while (idx < 0 || idx > listToRandom.length - 1) {
            idx = Math.round(distribution.ppf(Math.random()));
        }
        return listToRandom[idx];
    };

    //============================= GENDER/LIST ===================================
    updateGender = (newOption) => {
        this.setState({
            gender: update(this.state.gender, {option: {$set: newOption}})
        }, () => this.createNewName(true));
    };

    getNameListsToUseNorm = (card, gender) => {
        if (gender === "Female") {
            return card.title === "Fornafn" ? girlFirstNamesOrdered : girlMiddleNamesOrdered;
        } else {
            return card.title === "Fornafn" ? boyFirstNamesOrdered : boyMiddleNamesOrdered;
        }
    };

    getGender = () => {
        if (this.state.gender.option === "Male") {
            return "Male";
        } else if (this.state.gender.option === "Female") {
            return "Female"
        } else {
            return Math.floor(Math.random() * 2) === 0 ? "Male" : "Female"
        }
    };

    //============================= QUERY STRING ===================================
    updateQueryString = (name) => {
        if (name) {
            try {
                history.replace('/' + this.generateShareUrl());
            } catch (e) {
                //Not mission critical
            }
        }
    };

    generateShareUrl = () => {
        let nameString = "";
        let cardString = "";
        for (const card of this.state.cards) {
            let cardToAdd = card.title[0] + card.type[0];

            nameString += nameString.length <= 0 ? card.input : "-" + card.input;
            cardString += cardString.length <= 0 ? cardToAdd : "-" + cardToAdd;
        }
        let genderOption = "o";
        if (this.state.gender) {
            genderOption = this.state.gender.option.charAt(0);
        }

        return '?' + queryString.stringify({n: nameString, c: cardString.toLowerCase(), g: genderOption.toLowerCase()});
    };

    initCardsFromQueryString = (parsedNameList, parsedCardList, genderOption) => {
        let cardList = [];
        for (const [index, card] of Object.entries(Object.values(parsedCardList))) {
            let title = card.charAt(0) === "e" ? "Eftirnafn" : (card.charAt(0) === "f" ? "Fornafn" : "Millinafn");
            let deletable = title !== "Fornafn" && title !== "Eftirnafn";
            let type = card.charAt(1) === "c" ? "CustomNameInput" : "RandomNameInput";
            let input = this.cleanInput(parsedNameList[index]);

            cardList.push(this.createCard(title, deletable, type, 80, input))
        }
        genderOption = genderOption === "m" ? "Male" : (genderOption === "f" ? "Female" : "Other");

        this.setState({
            cards: cardList,
            gender: update(this.state.gender, {option: {$set: genderOption}})
        }, () => this.updateHistory());
    };

    //==============================================================================
    //============================ NAME HISTORY ====================================
    //==============================================================================
    toggleNameHistoryHeart = (key) => {
        if (key >= 0) {
            if (this.state.nameHistory[key].name === this.getNameOnDisplay()) {
                this.setState({heartCheck: !this.state.nameHistory[key].heartCheck})
            }
            this.setState({
                nameHistory: update(this.state.nameHistory, {[key]: {heartCheck: {$set: !this.state.nameHistory[key].heartCheck}}})
            }, () => this.updateHistoryLocalStorage());
        } else {
            let newHeartCheckState = !this.state.heartCheck;

            this.setState({
                heartCheck: newHeartCheckState
            }, () => this.updateHistoryLocalStorage());

            const historyIndex = this.getHistoryIndexOfName(this.getNameOnDisplay());
            if (historyIndex > -1) {
                this.setState({
                    nameHistory: update(this.state.nameHistory, {[historyIndex]: {heartCheck: {$set: newHeartCheckState}}})
                }, () => this.updateHistoryLocalStorage());
            }
        }
    };

    updateHistory = () => {
        const name = this.getNameOnDisplay();
        if (name) {
            const historyIndex = this.getHistoryIndexOfName(name);
            if (historyIndex > -1) {
                this.setState({
                    heartCheck: false
                });
            } else {
                let updateLocalHistory = this.state.heartCheck;
                this.setState({
                    nameHistory: update(this.state.nameHistory, {$unshift: [{name: name, cardState: this.state.cards, heartCheck: this.state.heartCheck, genderOption: this.state.gender.option}]}),
                    heartCheck: false
                }, () => updateLocalHistory && this.updateHistoryLocalStorage());
            }
        }
    };

    getHistoryIndexOfName = (name) => {
        return this.state.nameHistory.findIndex(function (history) {
            return history.name === name;
        })
    };

    rollBackNameFromHistory = (nameFromHistory) => {
        let genderOption = "Other";
        if (nameFromHistory.genderOption) {
            genderOption = nameFromHistory.genderOption;
        }

        let cardList = [];
        for (const card of nameFromHistory.cardState) {
            let randomness = card.randomness ? card.randomness : 80;
            cardList.push(this.createCard(card.title, card.deletable, card.type, randomness, card.input))
        }
        this.setState({
            heartCheck: nameFromHistory.heartCheck,
            cards: cardList,
            gender: update(this.state.gender, {option: {$set: genderOption}})
        }, () => this.updateQueryString(nameFromHistory.name));
    };

    //======================== Local Storage =======================
    initNameHistory = () => {
        try {
            return JSON.parse(localStorage.nameHistory);
        } catch (e) {
            localStorage.nameHistory = [];
            return [];
        }
    };

    updateHistoryLocalStorage = () => {
        localStorage.heartCheck = this.state.heartCheck;
        let historyToSave = [];
        for (const nameInHistory of this.state.nameHistory) {
            if (nameInHistory.heartCheck) {
                historyToSave.push(nameInHistory);
            }
        }
        localStorage.nameHistory = JSON.stringify(historyToSave);
    };

    //==============================================================================
    //================================ OTHERS ======================================
    //==============================================================================
    initGA = () => {
        if (!(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
            ReactGA.initialize('UA-9415153-7');
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    };

    cleanInput = (input) => {
        if (input) {
            return input.replace(/[^AÁBCDÐEÉFGHIÍJKLMNOÓPQRSTUÚVWXYÝZÞÆÖaábcdðeéfghiíjklmnoópqrstuúvwxyýzþæö ]/gi, '').trim();
        }
        return "";
    };

    sendGAEventForNewName = (name) => {
        ReactGA.event({
            category: 'User',
            action: 'createNewName',
            label: name,
        });
    };

    getRandomColor = function () {
        let randomColors = ["#B85878", "#CC6583", "#e6a0ae", "#347DB1", "#2768A5", "#2ca1b1"];
        return randomColors[Math.floor(Math.random() * randomColors.length)];
    };

    render() {
        return (
            <React.Fragment>
                <Template data={this.state}/>
            </React.Fragment>
        )
    }
}

export default Index;