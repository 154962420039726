export default [
    'Sumarlín',
    'Vök',
    'Alína',
    'Rósinkransa',
    'Kristin',
    'Bjargdís',
    'Brandís',
    'Atlanta',
    'Bría',
    'Orka',
    'Addú',
    'Þalía',
    'Rökkva',
    'Amy',
    'Lílý',
    'Sólín',
    'Róska',
    'Selina',
    'Nenna',
    'Santía',
    'Atalía',
    'Gunnharða',
    'Bláklukka',
    'Avía',
    'Silfur',
    'Gísela',
    'Sissa',
    'Lalíla',
    'Snjáka',
    'Lúna',
    'Matta',
    'Morgunsól',
    'Elddís',
    'Marit',
    'Zíta',
    'Eirún',
    'Rannva',
    'Rósalía',
    'Aríela',
    'Stefana',
    'Pría',
    'Christa',
    'Karó',
    'Jólín',
    'Mýrún',
    'Dissý',
    'Hedí',
    'Dúnna',
    'Sivía',
    'Akira',
    'Íssól',
    'Hjörtfríður',
    'Emeralda',
    'Eldþóra',
    'Skugga',
    'Bergsveina',
    'Elenborg',
    'Jósefín',
    'Sirra',
    'Randý',
    'Bergheiður',
    'List',
    'Lilla',
    'Viglín',
    'Aríaðna',
    'Kellý',
    'Lella',
    'Botnía',
    'Sigvalda',
    'Bentey',
    'Carla',
    'Ava',
    'Vigný',
    'Maía',
    'Dóróþea',
    'Adelía',
    'Kókó',
    'Jovina',
    'Snæfríð',
    'Gola',
    'Árún',
    'Randí',
    'Hinrikka',
    'Blín',
    'Bjarný',
    'Lilley',
    'Malína',
    'Alena',
    'Gumma',
    'Sónata',
    'Julia',
    'Arntinna',
    'Nikíta',
    'Adríana',
    'Mæja',
    'Elíndís',
    'Lýra',
    'Elba',
    'Björney',
    'Dallilja',
    'Cathinca',
    'Asírí',
    'Filipía',
    'Jane',
    'Kalmara',
    'Húna',
    'Árvök',
    'Luna',
    'Arja',
    'Abel',
    'Millý',
    'Katharina',
    'Siv',
    'Hleiður',
    'Susie',
    'Patrisía',
    'Bernódía',
    'Rökkurdís',
    'Nancy',
    'Beata',
    'Serena',
    'Júlíhuld',
    'Úlfey',
    'Estel',
    'Júní',
    'Nala',
    'Andríana',
    'Karí',
    'Ýrún',
    'Myrk',
    'Ilse',
    'Vetrarrós',
    'Nóa',
    'Kira',
    'Isabel',
    'Manasína',
    'Ósklín',
    'Elsí',
    'Zophía',
    'Ást',
    'Brynný',
    'Erin',
    'Korka',
    'Linddís',
    'Módís',
    'Amilía',
    'Evey',
    'Ripley',
    'Silfra',
    'Gret',
    'Vagnfríður',
    'Móa',
    'Tristana',
    'Karol',
    'Marella',
    'Elly',
    'Hrafnfífa',
    'Ollý',
    'Þorbrá',
    'Gilla',
    'Viktoria',
    'Avelin',
    'Alanta',
    'Helgey',
    'Björgey',
    'Elíza',
    'Gísla',
    'Melissa',
    'Einbjörg',
    'Petrós',
    'Róbjörg',
    'Dalrún',
    'Elsý',
    'Sigrid',
    'Aldey',
    'Manúela',
    'Emely',
    'Maria',
    'Georgía',
    'Veig',
    'Ýlfa',
    'Alexstrasa',
    'Caritas',
    'Marísól',
    'Katrína',
    'Naranja',
    'Jóhanndína',
    'Ótta',
    'Lexí',
    'Ora',
    'Katý',
    'Dóa',
    'Varða',
    'Hrafna',
    'Annelí',
    'Reyn',
    'Valgý',
    'Ey',
    'Emmý',
    'Alexis',
    'Guja',
    'Liljan',
    'Lingný',
    'Svea',
    'Guðna',
    'Ástey',
    'Rúbý',
    'Sjana',
    'Aría',
    'Febrún',
    'Einína',
    'Ermenga',
    'Elvi',
    'Sylvia',
    'Karún',
    'Liljurós',
    'Þyrnirós',
    'Ísbjört',
    'Valgerða',
    'Natasja',
    'Krumma',
    'Ára',
    'Hofdís',
    'Úranía',
    'Ófelía',
    'Nikoletta',
    'Indí',
    'Eilíf',
    'Víóla',
    'Sál',
    'Rein',
    'Kassandra',
    'Esí',
    'Bassí',
    'Binna',
    'Gnádís',
    'Franzisca',
    'Stjarney',
    'Hebba',
    'Sólrós',
    'Bót',
    'Náð',
    'Þollý',
    'Arabella',
    'Angelína',
    'Þeódís',
    'Leona',
    'Idda',
    'Tóbý',
    'Jarla',
    'Ramóna',
    'Júlína',
    'Marzibil',
    'Dáð',
    'Malía',
    'Maxima',
    'Drauma',
    'Eres',
    'Malika',
    'Líba',
    'Glóa',
    'Hanney',
    'Aleta',
    'Ýja',
    'Bergmannía',
    'Vibeka',
    'Benidikta',
    'Eyborg',
    'Villimey',
    'Álfrós',
    'Apríl',
    'Daðína',
    'Ævi',
    'Rorí',
    'Alisa',
    'Kala',
    'Bláey',
    'Eldlilja',
    'Matthilda',
    'Maríon',
    'Mathilda',
    'Snekkja',
    'Fold',
    'Valkyrja',
    'Júlírós',
    'Lísebet',
    'Tíalilja',
    'Bestla',
    'Elínóra',
    'Leila',
    'Þeba',
    'Gullý',
    'Leyla',
    'Ljóney',
    'Leóna',
    'Sæbrá',
    'Jonný',
    'Karma',
    'Júlí',
    'Gabriela',
    'Elvira',
    'Rea',
    'Mírey',
    'Minerva',
    'Jenetta',
    'Sirrí',
    'Míla',
    'Lofthildur',
    'Gígí',
    'Heida',
    'Asía',
    'Angelía',
    'Katerína',
    'Úna',
    'Kaía',
    'Selena',
    'Júlíetta',
    'Ínes',
    'Skjaldmey',
    'Óðný',
    'Símona',
    'Þingey',
    'Immý',
    'Eskja',
    'Frida',
    'Maísól',
    'Sessilía',
    'Þórða',
    'Mundína',
    'Eileiþía',
    'Tildra',
    'Kía',
    'Roxanna',
    'Elíanna',
    'Daría',
    'Abigael',
    'Eníta',
    'Victoria',
    'Ara',
    'Briet',
    'Hrafnynja',
    'Skaði',
    'Sylva',
    'Bobba',
    'Vár',
    'Eldmey',
    'Vinsý',
    'Svaney',
    'Engilrós',
    'Mímósa',
    'Eldrún',
    'Henrika',
    'Sæla',
    'Eyva',
    'Súsan',
    'Avelín',
    'Elea',
    'Olivia',
    'Trú',
    'Gratíana',
    'Grethe',
    'Obba',
    'Júdea',
    'Tía',
    'Araminta',
    'Ellín',
    'Þórsteinunn',
    'Ólivía',
    'Ebonney',
    'Marikó',
    'Elizabet',
    'Ísdögg',
    'Debora',
    'Kæja',
    'Ýma',
    'Mikkalína',
    'Sofie',
    'Aríel',
    'Aríana',
    'Jarún',
    'Vivian',
    'Voney',
    'Grein',
    'Gæfa',
    'Eivör',
    'Pía',
    'Ævör',
    'Mára',
    'Eybjört',
    'Stasía',
    'Dórey',
    'Íselín',
    'Mara',
    'Glytta',
    'Lokbrá',
    'Ranka',
    'Þrúða',
    'Linnea',
    'Jósebína',
    'Jenny',
    'Elinborg',
    'Sefanía',
    'Aðalrós',
    'Elínora',
    'Íslilja',
    'Dóris',
    'Finnrós',
    'Bil',
    'Kárí',
    'Alíana',
    'Lilly',
    'Karmen',
    'Louise',
    'Jannika',
    'Christel',
    'Yngveldur',
    'Frostrós',
    'Kikka',
    'Súlamít',
    'Gúa',
    'Sophia',
    'Míríel',
    'Sakura',
    'Hugbjört',
    'Manúella',
    'Venný',
    'Alparós',
    'Dimma',
    'Bebba',
    'Kristólína',
    'Irmelín',
    'Kristý',
    'Kittý',
    'Karlinna',
    'Gíta',
    'Patricia',
    'Níní',
    'Sophie',
    'Eiríksína',
    'Maríella',
    'Mýr',
    'Rósý',
    'Hædý',
    'Eufemía',
    'Blær',
    'Alida',
    'Ísidóra',
    'Aðalveig',
    'Aldný',
    'Aletta',
    'Alfífa',
    'Amadea',
    'Amý',
    'Andrá',
    'Aris',
    'Arisa',
    'Arín',
    'Armenía',
    'Arnóra',
    'Arnrós',
    'Atena',
    'Atla',
    'Auðbjört',
    'Axelía',
    'Árlaug',
    'Ársæl',
    'Ásfríður',
    'Áskatla',
    'Ásla',
    'Ásleif',
    'Ásný',
    'Ástveig',
    'Ásvör',
    'Begga',
    'Benía',
    'Beníta',
    'Bergrán',
    'Betanía',
    'Bíbí',
    'Bjargheiður',
    'Bjarglind',
    'Bjarngerður',
    'Bjarnþrúður',
    'Blíða',
    'Boghildur',
    'Borg',
    'Borgdís',
    'Borgþóra',
    'Burkney',
    'Dagheiður',
    'Daley',
    'Danheiður',
    'Danhildur',
    'Dendý',
    'Didda',
    'Dimmblá',
    'Drótt',
    'Egedía',
    'Eleina',
    'Elínheiður',
    'Elírós',
    'Elley',
    'ElsýElva',
    'Emelína',
    'Engla',
    'Enja',
    'Evfemía',
    'Eyfríður',
    'Eyhildur',
    'Eylín',
    'Eyveig',
    'Fema',
    'Finnbjörk',
    'Fía',
    'Fídes',
    'Fjalldís',
    'Frán',
    'Freylaug',
    'Freyleif',
    'Friðey',
    'Gauthildur',
    'Geirbjörg',
    'Geirhildur',
    'Geirlöð',
    'Geirríður',
    'Gilslaug',
    'Gissunn',
    'Gía',
    'Gjaflaug',
    'Gloría',
    'Gló',
    'Góa',
    'Grélöð',
    'Grét',
    'Grímey',
    'Guðmey',
    'Gullbrá',
    'Gunnfinna',
    'Gytta',
    'Hafbjörg',
    'Hafborg',
    'Hafliða',
    'Hafný',
    'Hallkatla',
    'Hallný',
    'Hallvör',
    'Heiðbjörg',
    'Heiðlaug',
    'Herbjört',
    'Hergerður',
    'Herlaug',
    'Hildibjörg',
    'Hildiríður',
    'Himinbjörg',
    'Hjálmgerður',
    'Hjálmrós',
    'Hjálmrún',
    'Hjörfríður',
    'Hrafnborg',
    'Hugdís',
    'Huldrún',
    'Húndís',
    'Húngerður',
    'Indra',
    'Ingimaría',
    'Ingisól',
    'Ír',
    'Íren',
    'Ísadóra',
    'Ísalind',
    'Ísdís',
    'Ísleif',
    'Ísmey',
    'Ísveig',
    'Janetta',
    'Jóa',
    'Jóngerð',
    'Jóný',
    'Jóríður',
    'Jökulrós',
    'Kamí',
    'Karína',
    'Ketilbjörg',
    'Ketilfríður',
    'Kjalvör',
    'Kormlöð',
    'Laíla',
    'Laufheiður',
    'Lauga',
    'Laugey',
    'Lárensína',
    'Leikný',
    'Liljá',
    'Lill',
    'Listalín',
    'Lífdís',
    'Lín',
    'Línbjörg',
    'Líndís',
    'Lofn',
    'Lousie',
    'Lóreley',
    'Lúðvíka',
    'Lúsinda',
    'Lúsía',
    'Lyngheiður',
    'Læla',
    'Maggey',
    'Magnheiður',
    'Maídís',
    'Maríkó',
    'Marínella',
    'Marísa',
    'Maríuerla',
    'Marja',
    'Markrún',
    'Mattíana',
    'Mánadís',
    'Meda',
    'Mey',
    'Mikaelína',
    'Milda',
    'Mirja',
    'Míranda',
    'Mítra',
    'Mjaðveig',
    'Mjalldís',
    'Náttsól',
    'Nella',
    'Niðbjörg',
    'Nýbjörg',
    'Oddleif',
    'Oddveig',
    'Oddvör',
    'Ormhildur',
    'Otkatla',
    'Óda',
    'París',
    'Rafney',
    'Ragnbjörg',
    'Ragnfríður',
    'Randalín',
    'Reginbjörg',
    'Róselía',
    'Rósinkara',
    'Rósmunda',
    'Rúndís',
    'Rúnhildur',
    'Rúrí',
    'Rögn',
    'Saldís',
    'Sarína',
    'Sera',
    'Sigurborg',
    'Sigurgeira',
    'Sigurnanna',
    'Silfa',
    'Silka',
    'Sía',
    'Sísí',
    'Síta',
    'Skuld',
    'Skúla',
    'Snorra',
    'Snæbrá',
    'Snælaug',
    'Sóla',
    'Sólgerður',
    'Sólvör',
    'Steindís',
    'Steinrós',
    'Stjarna',
    'Súla',
    'Svalrún',
    'Svanrós',
    'Sæhildur',
    'Sæný',
    'Teitný',
    'Teresía',
    'Todda',
    'Torbjörg',
    'Torfheiður',
    'Tóka',
    'Týra',
    'Úlfdís',
    'Úlfrún',
    'Valbjört',
    'Valdheiður',
    'Valería',
    'Valhildur',
    'Valþrúður',
    'Vébjörg',
    'Végerður',
    'Vilgerður',
    'Virginía',
    'Víf',
    'Víggunnur',
    'Vísa',
    'Von',
    'Þoka',
    'Þorgríma',
    'Þorlaug',
    'Þórelfa',
    'Þórodda',
    'Þrá',
    'Þúfa',
    'Ægileif',
    'Ögmunda',
    'Ölveig',
    'Abela',
    'Aðalbjört',
    'Aðaldís',
    'Aðalsteinunn',
    'Agnea',
    'Agneta',
    'Alexa',
    'Allý',
    'Analía',
    'Andra',
    'Angelíka',
    'Anína',
    'Annía',
    'Arinbjörg',
    'Aríella',
    'Arís',
    'Arnborg',
    'Arnfinna',
    'Arnika',
    'Arnkatla',
    'Arnlín',
    'Arnþóra',
    'Askja',
    'Auðný',
    'Axelma',
    'Álfey',
    'Álfgerður',
    'Álfsól',
    'Árbjört',
    'Árney',
    'Árveig',
    'Ásrós',
    'Ástbjört',
    'Ástgerður',
    'Ástheiður',
    'Ástþóra',
    'Baldey',
    'Bergfríður',
    'Bergrín',
    'Birtna',
    'Bjartmey',
    'Björghildur',
    'Bogdís',
    'Braghildur',
    'Brák',
    'Brimdís',
    'Britt',
    'Bríanna',
    'Brynfríður',
    'Brynheiður',
    'Dalía',
    'Dara',
    'Debóra',
    'Dimmey',
    'Díma',
    'Draumey',
    'Dýrborg',
    'Dæja',
    'Dögun',
    'Edel',
    'Eðna',
    'Eggrún',
    'Egla',
    'Eiðný',
    'Eiðunn',
    'Eindís',
    'Einrún',
    'Eirfinna',
    'Eldbjörg',
    'Elektra',
    'Eleonora',
    'Elíná',
    'Elínbet',
    'Elvíra',
    'Emý',
    'Enea',
    'Enóla',
    'Etna',
    'Evgenía',
    'Eyrós',
    'Eyþrúður',
    'Finnboga',
    'Finnlaug',
    'Folda',
    'Fregn',
    'Friðdís',
    'Friðjóna',
    'Friðlaug',
    'Friðleif',
    'Gefjun',
    'Gefn',
    'Geira',
    'Geirfinna',
    'Gíslrún',
    'Glóbjört',
    'Glóð',
    'Gógó',
    'Guðsteina',
    'Gunnbjört',
    'Gunnleif',
    'Gunnlöð',
    'Gunnveig',
    'Gurrý',
    'Gyðja',
    'Gæflaug',
    'Hafþóra',
    'Hallborg',
    'Halley',
    'Hallgunnur',
    'Hallrún',
    'Hansa',
    'Hedda',
    'Heiðbjörk',
    'Heiðlóa',
    'Heiðný',
    'Heiðrós',
    'Herfríður',
    'Hersilía',
    'Herþrúður',
    'Hildisif',
    'Hind',
    'Hjalta',
    'Hjaltey',
    'Hjálmveig',
    'Hjörleif',
    'Hólmbjörg',
    'Hrafngerður',
    'Hrafnheiður',
    'Hrafnlaug',
    'Hugbjörg',
    'Húnbjörg',
    'Hvönn',
    'Hödd',
    'Ina',
    'India',
    'Indía',
    'Indíra',
    'Ingdís',
    'Ingheiður',
    'Inghildur',
    'Ingifinna',
    'Ingifríður',
    'Ingilín',
    'Ingirós',
    'Ingiveig',
    'Íma',
    'Ísbjörg',
    'Ísis',
    'Íslaug',
    'Ísrún',
    'Íunn',
    'Jónbjört',
    'Jóndóra',
    'Jónída',
    'Jóra',
    'Júníana',
    'Jökla',
    'Kapitola',
    'Karólín',
    'Kathinka',
    'Katinka',
    'Ketilríður',
    'Kiddý',
    'Kládía',
    'Klementína',
    'Koldís',
    'Kolgríma',
    'Konkordía',
    'Kristensa',
    'Kristíanna',
    'Kristjóna',
    'Kristlind',
    'Kristlín',
    'Laufhildur',
    'Leónóra',
    'Lilý',
    'Línhildur',
    'Lísandra',
    'Lív',
    'Logey',
    'Lúísa',
    'Magnes',
    'Malla',
    'Manda',
    'Marela',
    'Marheiður',
    'Marí',
    'Marían',
    'Maríana',
    'Marít',
    'Marlaug',
    'Marlís',
    'Marsa',
    'Martína',
    'Matthía',
    'Mattína',
    'Mattý',
    'Melinda',
    'Mildríður',
    'Milla',
    'Minný',
    'Míra',
    'Móníka',
    'Mundheiður',
    'Mundhildur',
    'Mýra',
    'Mörk',
    'Nanný',
    'Naomí',
    'Naómí',
    'Nóra',
    'Odda',
    'Oddfreyja',
    'Oddgerður',
    'Oddhildur',
    'Októvía',
    'Ormheiður',
    'Otta',
    'Ósa',
    'Pálhanna',
    'Pálheiður',
    'Pálhildur',
    'Peta',
    'Petrónella',
    'Pétrún',
    'Pollý',
    'Randíður',
    'Reyndís',
    'Reynheiður',
    'Rósborg',
    'Róslind',
    'Róslinda',
    'Rósný',
    'Runný',
    'Röfn',
    'Salín',
    'Selka',
    'Senía',
    'Septíma',
    'Sigdóra',
    'Sigga',
    'Sigmunda',
    'Signhildur',
    'Sigurásta',
    'Sigurdríf',
    'Sigurdrífa',
    'Sigurhildur',
    'Sigurunn',
    'Silfrún',
    'Silla',
    'Skarpheiður',
    'Snjófríður',
    'Snót',
    'Sólfríður',
    'Steinbjörg',
    'Steinborg',
    'Steinrún',
    'Stígheiður',
    'Stígrún',
    'Stína',
    'Sunniva',
    'Sunníva',
    'Svanþrúður',
    'Sveiney',
    'Sveinrós',
    'Sveinveig',
    'Sæbjört',
    'Sæborg',
    'Sælaug',
    'Sæsól',
    'Sölva',
    'Sölvey',
    'Tala',
    'Tera',
    'Tindra',
    'Tirsa',
    'Tíbrá',
    'Tóta',
    'Tryggvína',
    'Úa',
    'Úlfa',
    'Vagna',
    'Valbjörk',
    'Valka',
    'Venus',
    'Vélaug',
    'Vilný',
    'Vinbjörg',
    'Vinný',
    'Víbekka',
    'Vígdögg',
    'Ylfur',
    'Ylja',
    'Ylva',
    'Þeódóra',
    'Þorleif',
    'Þorstína',
    'Þórgunna',
    'Þórvör',
    'Þurí',
    'Þöll',
    'Örbrún',
    'Addý',
    'Aðalfríður',
    'Agða',
    'Alexandría',
    'Alída',
    'Alva',
    'Amíra',
    'Annalísa',
    'Aríanna',
    'Arnlaug',
    'Arnljót',
    'Athena',
    'Auðdís',
    'Auðlín',
    'Auðrún',
    'Árnheiður',
    'Ásborg',
    'Ástþrúður',
    'Barbára',
    'Benney',
    'Benta',
    'Bessí',
    'Bína',
    'Bjarma',
    'Blómey',
    'Boga',
    'Borgrún',
    'Braga',
    'Brá',
    'Brigitta',
    'Brimhildur',
    'Brit',
    'Britta',
    'Dilja',
    'Día',
    'Dísella',
    'Dorothea',
    'Dórothea',
    'Edil',
    'Einey',
    'Einfríður',
    'Eirdís',
    'Elía',
    'Elínbjört',
    'Elíngunnur',
    'Elvý',
    'Emilíanna',
    'Emilý',
    'Eneka',
    'Engilbjört',
    'Enika',
    'Esja',
    'Eyjalín',
    'Fannlaug',
    'Filippa',
    'Friðbjört',
    'Friðfinna',
    'Friðrós',
    'Friðrún',
    'Friðþóra',
    'Friðveig',
    'Fróðný',
    'Fura',
    'Gillý',
    'Gná',
    'Grímheiður',
    'Grímhildur',
    'Gunnbjörg',
    'Gunnborg',
    'Gunndóra',
    'Gunnella',
    'Gunnrún',
    'Haddý',
    'Hafrós',
    'Hella',
    'Herbjörg',
    'Hildigerður',
    'Hlökk',
    'Hrafney',
    'Hraundís',
    'Hrólfdís',
    'Ingey',
    'Ingibjört',
    'Ingrún',
    'Irja',
    'Írunn',
    'Jessý',
    'Jóanna',
    'Jónný',
    'Jórlaug',
    'Jörgína',
    'Kaðlín',
    'Kata',
    'Kára',
    'Kendra',
    'Kolfreyja',
    'Kristþóra',
    'Laugheiður',
    'Láretta',
    'Lárey',
    'Ljótunn',
    'Lúvísa',
    'Maddý',
    'Marfríður',
    'Margrjet',
    'Margunnur',
    'Marlín',
    'Marselína',
    'Marsý',
    'Mábil',
    'Mekkin',
    'Messíana',
    'Minney',
    'Mjallhvít',
    'Nadja',
    'Nansý',
    'Nellý',
    'Njála',
    'Páldís',
    'Pálín',
    'Pálmfríður',
    'Petrúnella',
    'Pétrína',
    'Reynhildur',
    'Rín',
    'Röskva',
    'Salína',
    'Salma',
    'Sigurdóra',
    'Sigurhelga',
    'Sigursteina',
    'Sigurvina',
    'Sólbrá',
    'Sólhildur',
    'Sólkatla',
    'Sumarlína',
    'Svanbjört',
    'Sveingerður',
    'Sylgja',
    'Sævör',
    'Sölvína',
    'Talía',
    'Tamar',
    'Tanía',
    'Tea',
    'Tryggva',
    'Undína',
    'Unna',
    'Unnbjörg',
    'Úlfheiður',
    'Vagnbjörg',
    'Vallý',
    'Villa',
    'Víóletta',
    'Þjóðhildur',
    'Þorfinna',
    'Æsgerður',
    'Ölrún',
    'Örk',
    'Alís',
    'Amal',
    'Anastasía',
    'Arey',
    'Arína',
    'Arngerður',
    'Árbjörg',
    'Árelía',
    'Árna',
    'Árþóra',
    'Ástfríður',
    'Benna',
    'Betsý',
    'Bogga',
    'Bríana',
    'Daggrós',
    'Dagmey',
    'Daldís',
    'Díanna',
    'Dúa',
    'Edit',
    'Efemía',
    'Elíana',
    'Elsie',
    'Emelíana',
    'Esmeralda',
    'Estiva',
    'Evíta',
    'Evlalía',
    'Eyleif',
    'Fanny',
    'Franziska',
    'Frigg',
    'Fönn',
    'Geirfríður',
    'Geirný',
    'Gestný',
    'Gestrún',
    'Gísley',
    'Gunný',
    'Haflína',
    'Hafsteina',
    'Hákonía',
    'Henríetta',
    'Hinrika',
    'Hjálmey',
    'Hjörný',
    'Ingiborg',
    'Ingilaug',
    'Inna',
    'Irpa',
    'Isabella',
    'Ísgerður',
    'Íshildur',
    'Járngerður',
    'Jónhildur',
    'Júdit',
    'Kalla',
    'Kapítóla',
    'Kleópatra',
    'Kolbjörg',
    'Kristgerður',
    'Kristvina',
    'Kría',
    'Lara',
    'Leonóra',
    'Lily',
    'Lísabet',
    'Loftveig',
    'Lúcía',
    'Magda',
    'Magney',
    'Margit',
    'Maríam',
    'Marólína',
    'Mattea',
    'Málhildur',
    'Melrós',
    'Mikaela',
    'Minna',
    'Mía',
    'Móey',
    'Móna',
    'Njóla',
    'Oddlaug',
    'Ólafína',
    'Pálrún',
    'Petronella',
    'Rikka',
    'Róslaug',
    'Rósmary',
    'Salgerður',
    'Sanna',
    'Seselía',
    'Sesilía',
    'Signa',
    'Sigurða',
    'Sigurfinna',
    'Sigurlilja',
    'Sigurlinn',
    'Silvana',
    'Snjáfríður',
    'Snærós',
    'Sonný',
    'Sólbrún',
    'Sóldögg',
    'Stefánný',
    'Steinhildur',
    'Styrgerður',
    'Svanheiður',
    'Sveinrún',
    'Sæfinna',
    'Sæfríður',
    'Valfríður',
    'Valva',
    'Valý',
    'Vanda',
    'Veróníka',
    'Ýrr',
    'Þórbjörg',
    'Þórelfur',
    'Þórhanna',
    'Þurý',
    'Aagot',
    'Ada',
    'Aðalborg',
    'Anetta',
    'Auðna',
    'Ármey',
    'Baldrún',
    'Bella',
    'Bengta',
    'Bera',
    'Berglín',
    'Beta',
    'Bjarnlaug',
    'Bjarnrún',
    'Bjarnþóra',
    'Björnfríður',
    'Blædís',
    'Bryngerður',
    'Dalla',
    'Daníella',
    'Dúfa',
    'Dýrley',
    'Finndís',
    'Finney',
    'Finnfríður',
    'Fransiska',
    'Friðborg',
    'Friðlín',
    'Gíslný',
    'Gíslunn',
    'Gunnheiður',
    'Hauður',
    'Hendrikka',
    'Hugljúf',
    'Irmý',
    'Íva',
    'Jónanna',
    'Jóndís',
    'Kornelía',
    'Kristfríður',
    'Kristrós',
    'Ljósbjörg',
    'Magnþóra',
    'Margot',
    'Marinella',
    'Marlena',
    'Marsilía',
    'Móheiður',
    'Munda',
    'Myrra',
    'Nana',
    'Páley',
    'Pálfríður',
    'Petrún',
    'Ragney',
    'Sabína',
    'Sabrína',
    'Salný',
    'Selja',
    'Sigurbára',
    'Sigurmunda',
    'Sigurþóra',
    'Silva',
    'Símonía',
    'Skúlína',
    'Snæborg',
    'Snærún',
    'Sólný',
    'Steinfríður',
    'Sæmunda',
    'Tína',
    'Úndína',
    'Valrún',
    'Veiga',
    'Ynja',
    'Þorkatla',
    'Þórsteina',
    'Þura',
    'Ögn',
    'Alba',
    'Alísa',
    'Alvilda',
    'Aníka',
    'Annabella',
    'Aurora',
    'Berglaug',
    'Björgheiður',
    'Cecilía',
    'Dagbjörg',
    'Daney',
    'Danía',
    'Dórótea',
    'Dýrunn',
    'Eirný',
    'Eldey',
    'Elka',
    'Engilráð',
    'Erlen',
    'Eyþóra',
    'Finnborg',
    'Flóra',
    'Gauja',
    'Geirdís',
    'Gullveig',
    'Hólmdís',
    'Hrafndís',
    'Hreindís',
    'Ingimunda',
    'Ísabel',
    'Járnbrá',
    'Karlína',
    'Lisbeth',
    'Maj',
    'Mardís',
    'Marselía',
    'Polly',
    'Rafnhildur',
    'Rósfríður',
    'Róslín',
    'Rósmarý',
    'Sigurfljóð',
    'Silfá',
    'Snæbjört',
    'Theresa',
    'Torfey',
    'Unndís',
    'Verónika',
    'Véný',
    'Viðja',
    'Þjóðbjörg',
    'Þula',
    'Bjarnhildur',
    'Borgný',
    'Bóthildur',
    'Branddís',
    'Dagfríður',
    'Danfríður',
    'Elina',
    'Elína',
    'Ellisif',
    'Ethel',
    'Finna',
    'Finnbjörg',
    'Geirrún',
    'Gestheiður',
    'Hafey',
    'Henrietta',
    'Herta',
    'Hildegard',
    'Inda',
    'Jóney',
    'Jónfríður',
    'Júnía',
    'Kolka',
    'Kristíana',
    'Lotta',
    'Mirjam',
    'Mirra',
    'Ninja',
    'Ólavía',
    'Ríta',
    'Róberta',
    'Sigurbjört',
    'Steinlaug',
    'Valrós',
    'Þorsteina',
    'Þórheiður',
    'Adela',
    'Aðalsteina',
    'Alberta',
    'Alrún',
    'Ardís',
    'Arnleif',
    'Árndís',
    'Berit',
    'Charlotte',
    'Dís',
    'Donna',
    'Dúna',
    'Elenóra',
    'Elsabet',
    'Erlín',
    'Eyvör',
    'Gunnjóna',
    'Hjálmdís',
    'Hlaðgerður',
    'Högna',
    'Ísfold',
    'Kamma',
    'Karín',
    'Lana',
    'Lukka',
    'Magga',
    'Malena',
    'Mona',
    'Nótt',
    'Pamela',
    'Rósanna',
    'Rósey',
    'Sigurbirna',
    'Sigurey',
    'Sofía',
    'Sveinhildur',
    'Úlla',
    'Valentína',
    'Þórarna',
    'Þórfríður',
    'Þórstína',
    'Albína',
    'Annetta',
    'Assa',
    'Bjarklind',
    'Brimrún',
    'Einhildur',
    'Emilíana',
    'Friðdóra',
    'Friðsemd',
    'Gunndís',
    'Gyðríður',
    'Hertha',
    'Jónbjörg',
    'Karolína',
    'Kirstín',
    'Kristey',
    'Lillian',
    'Lind',
    'Malen',
    'Margret',
    'Mínerva',
    'Natalie',
    'Norma',
    'Sirrý',
    'Steindóra',
    'Steiney',
    'Ugla',
    'Valey',
    'Anney',
    'Ársól',
    'Bergey',
    'Björt',
    'Doris',
    'Glóey',
    'Guðlín',
    'Helma',
    'Hrafnkatla',
    'Irene',
    'Ísafold',
    'Ísey',
    'Jenna',
    'Kaja',
    'Louisa',
    'Magný',
    'Marey',
    'Marína',
    'Ráðhildur',
    'Sigdís',
    'Sóllilja',
    'Sunnefa',
    'Valný',
    'Vilfríður',
    'Yrja',
    'Benný',
    'Bentína',
    'Bogey',
    'Carmen',
    'Dalrós',
    'Eir',
    'Elfur',
    'Fífa',
    'Friðmey',
    'Guðdís',
    'Heiðbrá',
    'Hlédís',
    'Hrafntinna',
    'Hugborg',
    'Hörn',
    'Jóninna',
    'Karla',
    'Katarína',
    'Kristine',
    'Lydia',
    'Magnfríður',
    'Nikólína',
    'Oktavía',
    'Pálmey',
    'Rósbjörg',
    'Róshildur',
    'Sigurleif',
    'Sigyn',
    'Svafa',
    'Sveinlaug',
    'Tanya',
    'Thea',
    'Valbjörg',
    'Vordís',
    'Agatha',
    'Baldvina',
    'Bergný',
    'Clara',
    'Eik',
    'Eybjörg',
    'Gabríella',
    'Hadda',
    'Iða',
    'Jara',
    'Judith',
    'Líneik',
    'Ljósbrá',
    'Magndís',
    'Matthea',
    'Málmfríður',
    'Nadia',
    'Sigurást',
    'Sigurósk',
    'Snæbjörg',
    'Sumarrós',
    'Tamara',
    'Tekla',
    'Þyrí',
    'Æsa',
    'Annika',
    'Arngunnur',
    'Ástbjörg',
    'Bjarghildur',
    'Cecilia',
    'Daðey',
    'Elna',
    'Guðfríður',
    'Huldís',
    'Ísól',
    'Júlíanna',
    'Kolbrá',
    'Kristel',
    'Metta',
    'Miriam',
    'Mónika',
    'Rún',
    'Sesselía',
    'Siggerður',
    'Alla',
    'Álfdís',
    'Bóel',
    'Christina',
    'Ingeborg',
    'Malín',
    'Marel',
    'Mekkín',
    'Ninna',
    'Rósalind',
    'Sigurhanna',
    'Sveina',
    'Agata',
    'Ann',
    'Bettý',
    'Dómhildur',
    'Gríma',
    'Hallbjörg',
    'Hervör',
    'Hróðný',
    'Marý',
    'Salome',
    'Sól',
    'Sólbjörg',
    'Sveinborg',
    'Sveinsína',
    'Þórveig',
    'Bjarkey',
    'Charlotta',
    'Evelyn',
    'Eygerður',
    'Filippía',
    'Gunnfríður',
    'Kirsten',
    'Magnúsína',
    'Oddbjörg',
    'Ronja',
    'Ösp',
    'Christine',
    'Elisabeth',
    'Freygerður',
    'Guðbjört',
    'Gunnvör',
    'Hjálmfríður',
    'Ilmur',
    'Jónasína',
    'Jónheiður',
    'Maggý',
    'Máney',
    'Mist',
    'Nicole',
    'Oddfríður',
    'Óla',
    'Þórleif',
    'Belinda',
    'Elinóra',
    'Friðný',
    'Halldís',
    'Heiðveig',
    'Kristborg',
    'Laila',
    'Liv',
    'Líf',
    'Sólbjört',
    'Susan',
    'Sveinfríður',
    'Særós',
    'Úrsúla',
    'Vildís',
    'Vilma',
    'Birgit',
    'Eiríka',
    'Elínbjörg',
    'Elísabeth',
    'Hallbera',
    'Magðalena',
    'Victoría',
    'Árnína',
    'Ástdís',
    'Dýrfinna',
    'Friðrikka',
    'Guðmundína',
    'Gunnþóra',
    'Huld',
    'Lillý',
    'Lydía',
    'Marie',
    'Rós',
    'Alfa',
    'Berghildur',
    'Elínrós',
    'Kristlaug',
    'Magna',
    'Renata',
    'Alice',
    'Amelía',
    'Bjartey',
    'Eyja',
    'Guðjóna',
    'Hermína',
    'Krista',
    'Kristína',
    'Kristný',
    'Kristveig',
    'Magnhildur',
    'Maja',
    'Mjöll',
    'Rita',
    'Sigþóra',
    'Dísa',
    'Droplaug',
    'Jasmín',
    'Konný',
    'Silvía',
    'Steinvör',
    'Svanbjörg',
    'Arnrún',
    'Dana',
    'Nadía',
    'Ástrún',
    'Fanndís',
    'Greta',
    'Guðborg',
    'Ída',
    'Lýdía',
    'Anika',
    'Ágústína',
    'Áshildur',
    'Edith',
    'Guðveig',
    'Hilma',
    'Arney',
    'Astrid',
    'Ingrid',
    'Steina',
    'Anja',
    'Friðgerður',
    'Ida',
    'Salbjörg',
    'Steinþóra',
    'Yrsa',
    'Alexía',
    'Amanda',
    'Álfrún',
    'Glódís',
    'Jósefína',
    'Antonía',
    'Móeiður',
    'Pála',
    'Torfhildur',
    'Angela',
    'Daníela',
    'Indiana',
    'Ingiríður',
    'Ýr',
    'Þyri',
    'Arnhildur',
    'Bjarnfríður',
    'Ella',
    'Indíana',
    'Ísold',
    'Jarþrúður',
    'Veronika',
    'Erika',
    'Geirlaug',
    'Gerða',
    'Rán',
    'Sveindís',
    'Sæbjörg',
    'Bjargey',
    'Irena',
    'Amalía',
    'Irma',
    'Þórkatla',
    'Geirþrúður',
    'Karin',
    'Anný',
    'Benedikta',
    'Bergrós',
    'Ellý',
    'Fríður',
    'Jódís',
    'Mary',
    'Ásbjörg',
    'Bertha',
    'Bjarndís',
    'Friðbjörg',
    'Jana',
    'Lísbet',
    'Marsibil',
    'Oddrún',
    'Sigfríð',
    'Adda',
    'Bjarnheiður',
    'Dögg',
    'Henný',
    'Jófríður',
    'Petrea',
    'Þórgunnur',
    'Þórlaug',
    'Gunnþórunn',
    'Hallgerður',
    'Urður',
    'Þórný',
    'Álfhildur',
    'Bjarnveig',
    'Hallveig',
    'Ríkey',
    'Þrúður',
    'Anne',
    'Camilla',
    'Dýrleif',
    'Friðrika',
    'Gabríela',
    'Heiðbjört',
    'Lína',
    'Maren',
    'Teresa',
    'Elena',
    'Emelía',
    'Solveig',
    'Theodóra',
    'Guðleif',
    'Inger',
    'Salvör',
    'Ingileif',
    'Karlotta',
    'Vaka',
    'Jensína',
    'Martha',
    'Úlfhildur',
    'Írena',
    'Lea',
    'Sóldís',
    'Dóróthea',
    'Hilda',
    'Vilhelmína',
    'Arnþrúður',
    'Sigurjóna',
    'Steingerður',
    'Bergrún',
    'Snæfríður',
    'Aþena',
    'Áróra',
    'Fanný',
    'Ebba',
    'Kara',
    'Sigurdís',
    'Hansína',
    'Svanborg',
    'Berta',
    'Gunnur',
    'Sigfríður',
    'Salka',
    'Agla',
    'Ína',
    'Petrína',
    'Ruth',
    'Natalía',
    'Svanlaug',
    'Sigurlín',
    'Sólborg',
    'Herborg',
    'Karítas',
    'Védís',
    'Tara',
    'Gíslína',
    'Heba',
    'Sigþrúður',
    'Gunnlaug',
    'Ylfa',
    'Heiður',
    'Helen',
    'Þóranna',
    'Elma',
    'Theódóra',
    'Salóme',
    'Dagrún',
    'Dröfn',
    'Líney',
    'Þórhalla',
    'Barbara',
    'Monika',
    'Perla',
    'Vera',
    'Marín',
    'Freydís',
    'Kamilla',
    'Árdís',
    'Arnfríður',
    'Lóa',
    'Arnbjörg',
    'Bergdís',
    'Snjólaug',
    'Súsanna',
    'Svana',
    'Hera',
    'Melkorka',
    'Auðbjörg',
    'Bríet',
    'Rúna',
    'Anita',
    'Kolfinna',
    'Svanfríður',
    'Hlíf',
    'Særún',
    'Magdalena',
    'Maríanna',
    'Saga',
    'Gígja',
    'Valborg',
    'Arnheiður',
    'Hildigunnur',
    'Lísa',
    'Bylgja',
    'Hlín',
    'Sigurlína',
    'Petra',
    'Ísabella',
    'Tanja',
    'Borghildur',
    'Hafrún',
    'Jakobína',
    'Hrund',
    'Ingigerður',
    'Diljá',
    'Lena',
    'Karitas',
    'Málfríður',
    'Emma',
    'Álfheiður',
    'Gróa',
    'Sædís',
    'Ástrós',
    'Heiðdís',
    'Esther',
    'Hallfríður',
    'Drífa',
    'Embla',
    'Karólína',
    'Snædís',
    'Iðunn',
    'Ásrún',
    'Regína',
    'Sveinbjörg',
    'Ósk',
    'Bergljót',
    'Júlíana',
    'Ólína',
    'Sunneva',
    'Vala',
    'Sæunn',
    'Díana',
    'Ástríður',
    'Svanhvít',
    'Elfa',
    'Sif',
    'Jórunn',
    'Sigurveig',
    'Ellen',
    'Dagmar',
    'Bjarney',
    'Sjöfn',
    'Sigurrós',
    'Hekla',
    'Sylvía',
    'Rut',
    'Ingveldur',
    'Olga',
    'Ásthildur',
    'Signý',
    'Stella',
    'Árný',
    'Klara',
    'Nína',
    'Silja',
    'Elínborg',
    'Ásgerður',
    'Svandís',
    'Alma',
    'Pálína',
    'Svala',
    'Una',
    'Jenný',
    'Matthildur',
    'Fríða',
    'Gréta',
    'Aðalbjörg',
    'Hugrún',
    'Gerður',
    'Bergþóra',
    'Þorgerður',
    'Elísa',
    'Selma',
    'Birgitta',
    'Lovísa',
    'Guðmunda',
    'Eydís',
    'Gyða',
    'Aldís',
    'Nanna',
    'Sesselja',
    'Ólafía',
    'Guðfinna',
    'Sonja',
    'Heiða',
    'Heiðrún',
    'Brynhildur',
    'Dóra',
    'Eygló',
    'Valdís',
    'Björk',
    'Oddný',
    'Magnea',
    'Dagbjört',
    'Þórey',
    'Þórhildur',
    'Sólrún',
    'Kristbjörg',
    'Arndís',
    'Thelma',
    'Hrönn',
    'Eyrún',
    'Alda',
    'Bára',
    'Fjóla',
    'Svanhildur',
    'Gunnhildur',
    'Vilborg',
    'Ragna',
    'Kristrún',
    'Herdís',
    'Þuríður',
    'Guðríður',
    'Rebekka',
    'Katla',
    'Fanney',
    'Ester',
    'Vigdís',
    'Svava',
    'Ása',
    'Alexandra',
    'Þorbjörg',
    'Rannveig',
    'Birta',
    'Soffía',
    'Júlía',
    'Elva',
    'Elsa',
    'Freyja',
    'Sigurlaug',
    'Viktoría',
    'Agnes',
    'Ingunn',
    'Ágústa',
    'Marta',
    'Halla',
    'Sunna',
    'Dagný',
    'Laufey',
    'Björg',
    'Áslaug',
    'Rósa',
    'Aðalheiður',
    'Hrefna',
    'Hólmfríður',
    'Ragnhildur',
    'Stefanía',
    'Arna',
    'Aníta',
    'Sóley',
    'Hjördís',
    'Lára',
    'Kristjana',
    'Helena',
    'Brynja',
    'Jónína',
    'Guðlaug',
    'Sigurbjörg',
    'Andrea',
    'Emilía',
    'Tinna',
    'Hafdís',
    'Valgerður',
    'Sandra',
    'Harpa',
    'Telma',
    'Edda',
    'Hanna',
    'Linda',
    'Karen',
    'Erna',
    'Halldóra',
    'Jóna',
    'Þórdís',
    'Birna',
    'Rakel',
    'Þóra',
    'Hrafnhildur',
    'Unnur',
    'Þórunn',
    'Ásdís',
    'Íris',
    'Hulda',
    'Berglind',
    'Inga',
    'Bryndís',
    'Sólveig',
    'Kolbrún',
    'Auður',
    'Steinunn',
    'Ólöf',
    'Guðný',
    'Sara',
    'Erla',
    'Elísabet',
    'Guðbjörg',
    'Ásta',
    'Ragnheiður',
    'Lilja',
    'Eva',
    'Hildur',
    'Katrín',
    'Elín',
    'Jóhanna',
    'María',
    'Ingibjörg',
    'Sigrún',
    'Helga',
    'Margrét',
    'Sigríður',
    'Kristín',
    'Anna',
    'Guðrún'
];
