import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ShareOnMenu from "./SocialMediaMenu";

import {faHeart} from '@fortawesome/free-solid-svg-icons'


const styles = theme => ({
    refreshButton: {
        margin: '0 3em 0 3em',
    },
    refreshIcon: {
        fontSize: "2.2rem",
    },

    heartButton: {
        padding: '0.3rem'
    },
    heartIcon: {
        fontSize: "1.7rem",
    },
    toolbarButtons: {
        minWidth: '128px',
        marginLeft: 'auto',
        top: '65px',
        width: '100%',
        position: 'absolute',
        textAlign: 'center',
    },
});

class CenterIconMenu extends React.Component {
    state = {
        checked: 0,
    };

    handleToggle = () => {
        this.props.data.toggleNameHistoryHeart();
    };

    render() {
        const {classes} = this.props;

        return (
            <section id="centerIconMenuCss" className={classes.toolbarButtons}>
                <ShareOnMenu shareMenuUrl={this.props.data.shareMenuUrl} getNameOnDisplay={this.props.data.getNameOnDisplay}/>
                <IconButton className={classes.refreshButton} onClick={() => this.props.data.createNewName(true)}>
                    <RefreshIcon className={classes.refreshIcon}/>
                </IconButton>
                <IconButton className={classes.heartButton} onClick={this.handleToggle}>
                    <Checkbox className={classes.heartIcon} icon={<FontAwesomeIcon icon={faHeart}/>} checkedIcon={<FontAwesomeIcon icon={faHeart}/>} checked={this.props.data.heartCheck} tabIndex={-1}/>
                </IconButton>
            </section>
        );
    }
}

CenterIconMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CenterIconMenu);